import { HubConnection } from '@microsoft/signalr';
import React, { useContext, useEffect, useState } from 'react';
import { IDota2LobbyState } from '../../models/dto/lobby/Dota2/IDota2LobbyState';
import Lottie from 'react-lottie';
import animationData from '../../assets/animations/coinFlip.json';
import CustomSpinner1 from '../CustomSpinners/CustomSpinner1';
import { TypeAnimation } from 'react-type-animation';
import './Lobby.css';
import { useParams } from 'react-router-dom';
import { AppContext } from '../AppContext';
import { useModal } from '../../hooks/useModal';
import { Modal } from 'react-bootstrap';
import { CheckLg, Clipboard } from 'react-bootstrap-icons';
import { IDotaMapSettings } from '../../models/dto/lobby/Dota2/IDotaMapSettings';
import { ILobbyTeamBase } from '../../models/dto/lobby/LobbyBase/ILobbyTeamBase';
import {CommonConstants} from "../../helpers/constants/commonConstants";

const Dota2Lobby: React.FC<{ lobbyState: IDota2LobbyState, connection: HubConnection }> = ({ lobbyState, connection }) => {
    const { matchLobbyId } = useParams<{ matchLobbyId: string }>();
    const { player } = useContext(AppContext);
    const [loading, setLoading] = useState(true);
    const [showAnimation, setShowAnimation] = useState(true);
    const [coinValue, setCoinValue] = useState<string>('');
    const [currentCaptainId, setCurrentCaptainId] = useState<string | null>(null);
    const [resultShown, setResultShown] = useState(false);
    const { isVisible: showServerLoadingModal, openModal: openServerLoadingModal, closeModal: closeServerLoadingModal } = useModal();
    const [lobbyNameCopied, setLobbyNameCopied] = useState(false);
    const [lobbyPasswordCopied, setLobbyPasswordCopied] = useState(false);
    const [firstPickChosen, setFirstPickChosen] = useState(false);
    const [sideChosen, setSideChosen] = useState<number | null>(null);
    const [currentMapSettings, setCurrentMapSettings] = useState<IDotaMapSettings | undefined>(undefined);
    const [isChoiceMade, setIsChoiceMade] = useState(false);

    const isCurrentCaptain = player?.playerId === currentCaptainId;

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const handleCopy = async (copyText: string, setIsCopied: any) => {
        if (navigator?.clipboard) {
            await navigator.clipboard.writeText(copyText);
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 2500);
        } else {
            console.warn('Clipboard API не поддерживается в этом браузере.');
        }
    };

    const handleFirstPickClick = async () => {
        setIsChoiceMade(true);
        if (isCurrentCaptain) {
            const team = getTeamByPlayer(player.playerId ?? '');
            await connection.invoke("ChoiceCMPick", matchLobbyId, team.teamId);
        }
    }
    const handleSecondPickClick = async () => {
        setIsChoiceMade(true);
        if (isCurrentCaptain) {
            const team = getOpponentTeamByPlayer(player.playerId ?? '');
            await connection.invoke("ChoiceCMPick", matchLobbyId, team.teamId);
        }
    }

    const handleRadiantDireClick = async (side: number) => {
        setIsChoiceMade(true);
        if (isCurrentCaptain) {
            const team = getTeamByPlayer(player.playerId ?? '');
            await connection.invoke("ChoiceSide", matchLobbyId, team.teamId, side);
        }
    };

    useEffect(() => {
        if (lobbyState?.dotaMapSettings && lobbyState?.dotaMapSettings.length > 0) {
            setCurrentMapSettings(lobbyState?.dotaMapSettings[lobbyState.currentLobbyMapNumber - 1]);
        }
    }, [connection, lobbyState]);

    const getTeamByPlayer = (playerId: string): ILobbyTeamBase => {
        if (lobbyState.teamA.players.find(p => p.memberId == playerId)) {
            return lobbyState.teamA;
        } else if (lobbyState.teamA.standins.find(p => p.memberId == playerId)) {
            return lobbyState.teamA;
        } else if (lobbyState.teamB.standins.find(p => p.memberId == playerId)) {
            return lobbyState.teamB;
        } else {
            return lobbyState.teamB;
        }
    }
    const getOpponentTeamByPlayer = (playerId: string): ILobbyTeamBase => {
        if (lobbyState.teamA.players.find(p => p.memberId == playerId)) {
            return lobbyState.teamB;
        } else {
            return lobbyState.teamA;
        }
    }

    const calculateTeamScore = () => {
        let teamAScore = 0;
        let teamBScore = 0;

        if (!lobbyState?.dotaMapSettings) {
            return { teamAScore, teamBScore };
        }

        lobbyState.dotaMapSettings.forEach(map => {
            if (map.teamWinnerId === lobbyState.teamA.teamId) {
                teamAScore += 1;
            } else if (map.teamWinnerId === lobbyState.teamB.teamId) {
                teamBScore += 1;
            }
        });

        return { teamAScore, teamBScore };
    };

    const getPlayerTeamSettings = (playerId: string | null | undefined): { firstPick: boolean; side: string } | null | undefined => {
        if (!playerId) return null;
        const playerTeam = getTeamByPlayer(playerId);

        if (!playerTeam) return null;

        const isFirstPick = currentMapSettings?.cmfpTeamId === playerTeam.teamId;

        const side = currentMapSettings?.sides[playerTeam.teamId] === 1 ? 'Radiant' : 'Dire';

        return {
            firstPick: isFirstPick,
            side: side
        };
    };

    const getAllTeamSettings = (): { teamA: { firstPick: boolean; side: string }; teamB: { firstPick: boolean; side: string } } => {
        const teamA = {
            firstPick: currentMapSettings?.cmfpTeamId === lobbyState.teamA.teamId,
            side: currentMapSettings?.sides[lobbyState.teamA.teamId] === 1 ? 'Radiant' : 'Dire',
        };

        const teamB = {
            firstPick: currentMapSettings?.cmfpTeamId === lobbyState.teamB.teamId,
            side: currentMapSettings?.sides[lobbyState.teamB.teamId] === 1 ? 'Radiant' : 'Dire',
        };

        return { teamA, teamB };
    };

    useEffect(() => {
        setSideChosen(null);
        setFirstPickChosen(false);
    }, [lobbyState.currentLobbyMapNumber]);

    useEffect(() => {
        const { coinFlipWinnerTeamId, currentLobbyMapNumber } = lobbyState;

        if (!coinFlipWinnerTeamId || !currentLobbyMapNumber || !currentMapSettings) return;

        if (coinFlipWinnerTeamId !== CommonConstants.GuidEmpty) {
            setLoading(false);

            const fpChosen = currentMapSettings.cmfpTeamId !== CommonConstants.GuidEmpty;
            if (fpChosen) {
                setFirstPickChosen(true);
            }

            const sideAlreadyChosen = Object.values(currentMapSettings.sides).find(value => value === 1 || value === 2);
            setSideChosen(sideAlreadyChosen !== undefined ? sideAlreadyChosen : null);

            if (fpChosen || sideAlreadyChosen) {
                setCurrentCaptainId(currentMapSettings.secondPickCaptainId);
                const currentTeam = getTeamByPlayer(currentMapSettings.secondPickCaptainId);
                setCoinValue(`Выбирает команда: ${currentTeam.teamName}`);
            } else {
                setCurrentCaptainId(currentMapSettings.firstPickCaptainId);
                const currentTeam = getTeamByPlayer(currentMapSettings.firstPickCaptainId);
                setCoinValue(`Выбирает команда: ${currentTeam.teamName}`);
            }
        }
    }, [lobbyState.coinFlipWinnerTeamId, currentMapSettings]);

    useEffect(() => {
        if (firstPickChosen && sideChosen !== null) {
            openServerLoadingModal();
        }
    }, [sideChosen, firstPickChosen])

    const { teamAScore = 0, teamBScore = 0 } = calculateTeamScore();

    const teamSettings = player?.playerId
        ? getPlayerTeamSettings(player.playerId)
        : null;

    const isOthers = player && player.playerId && lobbyState.others.some(other => other.memberId === player.playerId);

    let firstPick = false;
    let side = 'Unknown';
    let allTeamSettings = null;

    if (isOthers) {
        allTeamSettings = getAllTeamSettings();
    } else if (teamSettings) {
        firstPick = teamSettings.firstPick;
        side = teamSettings.side;
    }

    return (
        <div className='dota2_lobby_main_container'>
            <div className="score-container">
                <div>ТЕКУЩИЙ СЧЕТ</div>
                <span>{teamAScore} : {teamBScore} </span>
            </div>
            <div className='dota2_lobby_button'>
                {!showServerLoadingModal && firstPickChosen && sideChosen !== null && (
                    <button className='serverModalButton' onClick={() => openServerLoadingModal()}>Подключение к Лобби</button>
                )}
            </div>
            {(!sideChosen || !firstPickChosen) ? (
                <>
                    {loading ? (
                        <CustomSpinner1 sizeSpinnerContainer='150px' size='150px' />
                    ) : showAnimation ? (
                        <div>
                            <Lottie
                                options={defaultOptions}
                                width={400}
                                speed={0.5}
                                eventListeners={[
                                    {
                                        eventName: "complete",
                                        callback: () => setShowAnimation(false)
                                    }
                                ]}
                                isClickToPauseDisabled={true} />
                        </div>
                    ) : (
                        <div className='dota_coinflow_container'>
                            <TypeAnimation
                                key={coinValue}
                                sequence={[
                                    coinValue,
                                    1000,
                                    () => {
                                        setResultShown(true);
                                    }
                                ]}
                                cursor={true}
                                style={{ fontSize: '26px', fontFamily: "Nasalization Rg" }}
                            />
                            {resultShown && (
                                <div className={'dota_choice_container'}>
                                    {!firstPickChosen && (
                                        <div className={`dota_choice_side_container ${(sideChosen) ? 'active' : ''}`}>
                                            <button
                                                className={`neon_button fp dota_choice ${firstPickChosen ? 'inactive' : ''}`}
                                                disabled={!isCurrentCaptain || isChoiceMade}
                                                onClick={handleFirstPickClick}>
                                                First Pick
                                            </button>
                                            <button
                                                className={`neon_button fp dota_choice ${firstPickChosen ? 'inactive' : ''}`}
                                                disabled={!isCurrentCaptain || isChoiceMade}
                                                onClick={handleSecondPickClick}>
                                                Second Pick
                                            </button>
                                        </div>
                                    )}
                                    {!sideChosen && !firstPickChosen && (
                                        <span>/</span>
                                    )}
                                    {!sideChosen && (
                                        <div className={`dota_choice_side_container ${(firstPickChosen) ? 'active' : ''}`}>
                                            <button
                                                className={`neon_button dire dota_choice`}
                                                disabled={!isCurrentCaptain || sideChosen !== null || isChoiceMade}
                                                onClick={() => handleRadiantDireClick(2)}
                                            >
                                                Dire
                                            </button>
                                            <button
                                                className={`neon_button dota_choice`}
                                                disabled={!isCurrentCaptain || sideChosen !== null || isChoiceMade}
                                                onClick={() => handleRadiantDireClick(1)}
                                            >
                                                Radiant
                                            </button>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </>
            ) : (
                <div className='dota_lobby_settings'>
                    {isOthers ? (
                        <div className='dota_lobby_adminsettings'>
                            <div>
                                <h4>{lobbyState.teamA.teamName}:</h4>
                                <div>{allTeamSettings?.teamA.firstPick ? 'First Pick' : 'Second Pick'}</div>
                                <div>{allTeamSettings?.teamA.side}</div>
                            </div>
                            <div>
                                <h4>{lobbyState.teamB.teamName}:</h4>
                                <div>{allTeamSettings?.teamB.firstPick ? 'First Pick' : 'Second Pick'}</div>
                                <div>{allTeamSettings?.teamB.side}</div>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className='dota_lobby_chosen_option'>
                                Ваш пик:
                                <div className={'neon_button fp dota_choice'}>
                                    {firstPick ? 'First Pick' : 'Second Pick'}
                                </div>
                            </div>
                            <div className='dota_lobby_chosen_option'>
                                Ваша сторона:
                                <div className={`neon_button dota_choice ${(side === 'Radiant') ? '' : 'dire'}`}>
                                    {side}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            )}
            <Modal className='serverReady_modal' show={showServerLoadingModal} size='lg' onHide={closeServerLoadingModal}>
                <Modal.Body className='serverReady_modal_body'>
                    <Modal.Title className='serverReady_modal_title'>
                        {lobbyState?.lobbyName && lobbyState?.lobbyPassword ? 'Информация о подключении к лобби' : 'Ожидание ответа от сервера'}
                    </Modal.Title>
                    <hr></hr>
                    <div className='serverReady_modal_mainInfo'>
                        {
                            lobbyState?.lobbyName && lobbyState?.lobbyPassword ? (
                                <div className='server_ready_container'>
                                    <div>ДАННЫЕ ДЛЯ ПОДКЛЮЧЕНИЯ</div>
                                    <div className='server_connect_address'>
                                        Name: <i>{lobbyState?.lobbyName}</i>
                                        {lobbyNameCopied ? (
                                            <CheckLg />
                                        ) : (
                                            <Clipboard onClick={() => handleCopy(lobbyState?.lobbyName, setLobbyNameCopied)} className='clipboard_icon' />
                                        )}
                                    </div>
                                    <div className='server_connect_address'>
                                        Password: <i>{lobbyState?.lobbyPassword}</i>
                                        {lobbyPasswordCopied ? (
                                            <CheckLg />
                                        ) : (
                                            <Clipboard onClick={() => handleCopy(lobbyState?.lobbyPassword, setLobbyPasswordCopied)} className='clipboard_icon' />
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className='server_not_ready_container'>
                                    <div>Запустите DOTA2, если вы этого еще не сделали</div>
                                    <CustomSpinner1 size={`100`} sizeSpinnerContainer='120px' />
                                </div>
                            )
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Dota2Lobby;

import './BracketOpponent.css';
import React, { useState } from "react";
import { Tooltip } from "react-tooltip";
import {
    useBracketData,
    useEditMode,
    useMatchHover,
    useOpponentHover,
    usePlayerTeam
} from '../../providers/TournamentBracketContext';
import { CommonConstants } from '../../helpers/Constants';
import { MatchTypeEnum } from "../../enums/MatchTypeEnum";
import { IMatch } from "../../interfaces/IMatch";

import firstPlaceIcon from '../../assets/first_place.svg'
import secondPlaceIcon from '../../assets/second_place.svg'
import thirdPlaceIcon from '../../assets/third_place.svg'
import {useNavigate} from "react-router-dom";

interface BracketOpponentProps {
    matchId: string;
    opponentId: string | number | null | undefined;
    opponentTag: string | null | undefined;
    opponentName: string | null | undefined;
    resultOpponent: string | number;
    isLower: boolean;
    resultTeamClassName: string;
    matchType: MatchTypeEnum | null;
    roundId: number;
    bracketType: string;
    onDropOpponent: (
        draggedId: string | number,
        targetId: string | number,
        sourceMatchId: string,
        targetMatchId: string
    ) => void;
}

const BracketOpponent: React.FC<BracketOpponentProps> = ({ 
    opponentId, 
    opponentTag, 
    opponentName,
    resultOpponent, 
    isLower, 
    resultTeamClassName,
    matchType,
    onDropOpponent,
    matchId,
    roundId,
    bracketType
}) => {
    const navigate = useNavigate();
    const [highlightedTarget, setHighlightedTarget] = useState<boolean>(false);
    const { hoveredOpponentId, setHoveredOpponentId } = useOpponentHover();
    const { playerTeamId } = usePlayerTeam();
    const { setHoveredMatchData } = useMatchHover();
    const { isEditMode } = useEditMode();
    const { contextBracketData } = useBracketData();

    const handleDragStart = (e: React.DragEvent) => {
        if (opponentId && isEditMode) {
            const dragData = JSON.stringify({ opponentId, matchId });
            e.dataTransfer.setData("application/json", dragData);
        }
    };

    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault();

        if (roundId === 1 && isEditMode) {
            if (opponentId !== hoveredOpponentId) {
                setHighlightedTarget(true);
            }
        }
    };

    const handleDrop = (e: React.DragEvent) => {
        e.preventDefault();
        const dragData = e.dataTransfer.getData("application/json");

        if (dragData) {
            const { opponentId: draggedId, matchId: sourceMatchId } = JSON.parse(dragData);

            let sourceMatch: IMatch | undefined = contextBracketData?.match.find(m => m.id === sourceMatchId);
            let targetMatch: IMatch | undefined = contextBracketData?.match.find(m => m.id === matchId);

            if (sourceMatch && targetMatch) {
                if (
                    (!sourceMatch.opponent1?.result && !sourceMatch.opponent1?.result) &&
                    (!targetMatch.opponent1?.result && !targetMatch.opponent2?.result)
                ) {
                    if (targetMatch.round_id === 1 && sourceMatch.round_id === 1) {
                        if (draggedId && opponentId) {
                            onDropOpponent(draggedId, String(opponentId), sourceMatchId, matchId);
                        }
                    }
                }
            }

        }

        setHighlightedTarget(false);
    };

    const handleMouseEnter = () => {
        if (opponentId) {
            setHoveredOpponentId(String(opponentId));
        }
    };

    const handleMouseLeave = () => {
        setHoveredOpponentId(null);
        setHoveredMatchData({
            matchForWinner: null,
            matchForLoser: null
        });
    };
    
    const isOpponentHighlighted = (): string => {
        if (opponentId && opponentId !== CommonConstants.GuidEmpty && isEditMode) {
            if (hoveredOpponentId === opponentId) {
                return 'selectedOpponent'
            }
        }
        
        return '';
    }

    const tooltip = (): string | null | undefined => {
        return !opponentId || opponentId === CommonConstants.GuidEmpty ? '' : opponentName;
    };

    const renderOpponentTag = (): React.JSX.Element => {
        return (
            <div
                onClick={() => window.open(`/team/${opponentId}`, '_blank')}
                data-tooltip-id={`tooltip-${opponentId}-${matchId}`}
                data-tooltip-content={tooltip()}
                className={
                    `
                        BracketMatch_opponent_tag 
                        ${hoveredOpponentId === opponentId && opponentId !== CommonConstants.GuidEmpty && opponentId ? 'selectedOpponent' : ''}
                        ${opponentId === playerTeamId ? 'playerTeam' : ''}
                        ${opponentId && opponentId !== CommonConstants.GuidEmpty ? 'hover' : ''}
                        ${isOpponentHighlighted()}
                    `
                }
            >
                {opponentTag}

                <Tooltip
                    id={`tooltip-${opponentId}-${matchId}`}
                    style={{
                        backgroundColor: '#201e1e',
                        color: '#fff',
                        fontSize: '10px',
                        borderRadius: '4px',
                        zIndex: 5
                    }}
                    delayShow={300}
                    place={isLower ? 'bottom' : "top"}
                />
            </div>
        )
    }

    const renderOpponentResult = () => {
        if (resultTeamClassName === 'win' && matchType === 4) {
            return <img className='BracketMatch_opponent_result_cup' alt={`${resultOpponent}`} src={firstPlaceIcon}/>
        } else if (resultTeamClassName === 'loss' && matchType === 4) {
            return <img className='BracketMatch_opponent_result_cup' alt={`${resultOpponent}`} src={secondPlaceIcon}/>
        } else if (bracketType === 'single_elimination' && resultTeamClassName === 'win' && matchType === 3) {
            return <img className='BracketMatch_opponent_result_cup' alt={`${resultOpponent}`} src={thirdPlaceIcon}/>
        } else if (bracketType !== 'single_elimination' && resultTeamClassName === 'loss' && matchType === 3) {
            return <img className='BracketMatch_opponent_result_cup' alt={`${resultOpponent}`} src={thirdPlaceIcon}/>
        } else {
            return resultOpponent;
        }
    }

    const renderOpponent = (): React.JSX.Element => {
        return (
            <div
                draggable={!!opponentId && isEditMode}
                onDrop={handleDrop}
                onDragStart={handleDragStart}
                onDragLeave={() => setHighlightedTarget(false)}
                onDragOver={handleDragOver}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className={`
                BracketMatch_opponent 
                ${isLower ? "lower" : ""} 
                ${highlightedTarget ? 'isSwapOpponents' : ''}
            `}
            >
                {renderOpponentTag()}
                <div className={`BracketMatch_opponent_result ${resultTeamClassName}Res`}>
                    {renderOpponentResult()}
                </div>
            </div>
        )
    }
    
    return (
        <>
            {renderOpponent()}
        </>
    );
};

export default BracketOpponent;
import React, { useContext, useEffect, useState } from 'react';
import './ChosenTournament.css';
import { Container, ProgressBar } from 'react-bootstrap';
import { getTournamentById, participateInTournament } from '../../http/tournament/tournamentActionsAPI';
import { useNavigate, useParams } from 'react-router-dom';
import { ITournamentInfoModel } from '../../models/dto/tournament/ITournamentInfoModel';
import { observer } from 'mobx-react-lite';
import { AppContext } from '../../components/AppContext';
import { ExclamationDiamondFill } from 'react-bootstrap-icons';
import MyAlert from '../../components/MyAlert/MyAlert';
import TeamItem from '../../components/TeamItem/TeamItem';
import CustomSpinner1 from '../../components/CustomSpinners/CustomSpinner1';
import { getGameTypeName } from '../../logic/CommonLogic';
import { approveTournamentByOrganisator } from '../../http/playerActionsAPI';
import GooeyButton from '../../components/Buttons/GooeyButton/GooeyButton';
import MatchesTimetable from '../../components/MatchesTimetable/MatchesTimetable';
import ResultsTable from '../../components/ResultsTable/ResultsTable';
import TournamentTypeMapping from '../../models/enums/tournament/TournamentTypeEnum';
import MatchesFormatEnumMapping from '../../models/enums/tournament/MatchesFormatEnum';
import TournamentBracket from "../../components/TournamentBracket/TournamentBracket";

const ChosenTournament: React.FC = observer(() => {
    const { player } = useContext(AppContext);
    const [activeTab, setActiveTab] = useState(0);
    const [fetchingTournament, setFetchingTournament] = useState(true);
    const [tournamentInfo, setTournamentInfo] = useState<ITournamentInfoModel>()
    const { tournamentId } = useParams()
    const navigate = useNavigate();
    const [participateComment, setParticipateComment] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [tournamentStarted, setTournamentStarted] = useState(false);
    const [reservedSlots, setReservedSlots] = useState<number>();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (tournamentInfo) {
            setReservedSlots(tournamentInfo.maxSlots - tournamentInfo.activeSlots);
        }
    }, [tournamentInfo])

    useEffect(() => {
        if (!tournamentInfo) {
            return;
        }

        const { tournamentTeams, maxSlots, activeSlots, organisatorName, approvedByOrganisator, approvedByAdmin, regDateStart } = tournamentInfo;
        const isParticipating = tournamentTeams.some(team => player?.playerInTeam(team.teamId ?? ''));
        const slotsAvailable = maxSlots - activeSlots > 0;
        const currentDate = new Date();
        const regDateStartDate = regDateStart ? new Date(regDateStart) : null;
        const normalizedPlayerLogin = player?.login?.toLowerCase();
        const normalizedOrganisatorName = organisatorName?.toLowerCase();

        const getParticipateComment = () => {
            if (normalizedPlayerLogin === normalizedOrganisatorName) {
                if (!tournamentInfo.fastCupFlag && !approvedByOrganisator && approvedByAdmin && regDateStartDate && currentDate < regDateStartDate) {
                    return 'РЕГИСТРАЦИЯ НАЧАЛАСЬ РАНЬШЕ ОДОБРЕНИЯ, ОТРЕДАКТИРУЙТЕ ТУРНИР'
                } else if (!approvedByOrganisator && approvedByAdmin) {
                    return 'НАЧАТЬ ТУРНИР';
                }
                if (!approvedByAdmin) {
                    return 'НА ОДОБРЕНИИ АДМИНОМ';
                }
            } else {
                if (!approvedByOrganisator || !approvedByAdmin) {
                    return 'ТУРНИР НА ОДОБРЕНИИ';
                }
            }

            if (!slotsAvailable) {
                return 'Все слоты заняты';
            }

            if (!player?.playerTeams
                || player.playerTeams.length === 0
                || !player?.getTeamByGameTypeId(tournamentInfo.gameTypeId)) {
                return 'Создайте команду для участия в турнире';
            }

            if (isParticipating) {
                return 'Вы уже участвуете в турнире';
            }
            if (regDateStartDate && currentDate < regDateStartDate) {
                return 'ОЖИДАЙТЕ НАЧАЛА РЕГИСТРАЦИИ';
            }

            return 'ПРИНЯТЬ УЧАСТИЕ';
        };

        setParticipateComment(getParticipateComment());
    }, [tournamentInfo, player]);

    useEffect(() => {
        if (tournamentId) {
            setFetchingTournament(true);
            getTournamentById(tournamentId)
                .then(data => {
                    if (data) {
                        setTournamentInfo(data);
                    }
                })
                .finally(() => setFetchingTournament(false));
        }
    }, [tournamentId]);

    const handleParticipate = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsLoading(true);
        participateInTournament(tournamentId, player?.getTeamByGameTypeId(tournamentInfo?.gameTypeId ?? '')?.teamId)
            .then(({ success, errorMessage }) => {
                setIsLoading(false);
                if (success) {
                    getTournamentById(tournamentId)
                        .then(data => {
                            if (data) {
                                setTournamentInfo(data);
                            }
                        });
                } else {
                    setAlertText(errorMessage || "Ошибка присоединения к турниру");
                    setShowAlert(true);
                }
            });
    };

    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    const startScheduler = () => {
        setIsLoading(true);
        approveTournamentByOrganisator(tournamentId)
            .then(data => {
                if (data) {
                    setIsLoading(false);
                    setTournamentStarted(true);
                }
            })
    };

    const convertTimeToLocal = (hours: number, minutes = 0) => {
        const date = new Date();
        date.setUTCHours(hours, minutes, 0, 0);

        const localHours = date.getHours();
        const localMinutes = date.getMinutes();

        return `${localHours.toString().padStart(2, '0')}:${localMinutes.toString().padStart(2, '0')}`;
    };

    const formatDate = (dateString: string | undefined) => {
        if (dateString === undefined) {
            return '';
        }
        const options: Intl.DateTimeFormatOptions = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        };
        return new Date(dateString).toLocaleString('ru-RU', options);
    }

    const isTournamentAdditionalSlots = (): number => {
        return (
            (tournamentInfo?.playerAdmins.length ?? 0) +
            (tournamentInfo?.observers.length ?? 0) +
            (tournamentInfo?.streamers.length ?? 0)
        );
    }

    return (
        <Container>
            <div className="chosen_tournament_container">
                <MyAlert
                    show={showAlert}
                    onHide={handleCloseAlert}
                    alertText={alertText}
                    buttons={[
                        { text: 'ОК', onClick: handleCloseAlert }
                    ]}
                />
                <div className="chosen_tournament_first_container">
                    {tournamentInfo ? (
                        <div className='headercard_container'>
                            <div className='chosen_game_info'>
                                <div className='chosen_game_short_info_pic_contaner'>
                                    <img className='chosen_game_short_info_pic' src={process.env.REACT_APP_IMG_STORAGE_URL + tournamentInfo?.logoUrl} alt="chosen_game" />
                                </div>
                                <div className='chosen_game_short_info'>
                                    <div>
                                        <div className='chosen_game_name'>
                                            {tournamentInfo.name}
                                        </div>
                                        <div className='chosen_game_genre'>
                                            {getGameTypeName(tournamentInfo.gameTypeId)}
                                        </div>
                                        <div className='chosen_game_tags'>
                                            <div className="tag">
                                                <p>
                                                    {tournamentInfo?.tournamentTypeEnum
                                                        ? TournamentTypeMapping[tournamentInfo.tournamentTypeEnum] || 'Неизвестный тип турнира'
                                                        : ''}
                                                </p>
                                            </div>
                                            <div className="tag">
                                                {tournamentInfo?.matchesFormatEnum
                                                    ? MatchesFormatEnumMapping[tournamentInfo.matchesFormatEnum] || 'Неизвестный тип турнира'
                                                    : ''}
                                            </div>
                                            <div className="tag">
                                                <p>{tournamentInfo?.upperMatchBracketTypeEnum}</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='chosen_game_socials_button_container'>
                                        <button
                                            className={activeTab === 0 ? 'chosen_game_socials_button active_tab' : 'chosen_game_socials_button'}
                                            onClick={() => setActiveTab(0)}
                                        >
                                            {tournamentInfo?.statusName === "Cancelled" || tournamentInfo?.statusName === "Finished" ? (
                                                "РЕЗУЛЬТАТЫ"
                                            ) : (
                                                "ОПИСАНИЕ"
                                            )}
                                        </button>

                                        {tournamentInfo.bracket && (
                                            <button
                                                className={activeTab === 1 ? 'chosen_game_socials_button active_tab' : 'chosen_game_socials_button'}
                                                onClick={() => setActiveTab(1)}
                                            >
                                                СЕТКА
                                            </button>
                                        )}

                                        <button
                                            className={activeTab === 2 ? 'chosen_game_socials_button active_tab' : 'chosen_game_socials_button'}
                                            onClick={() => setActiveTab(2)}
                                        >
                                            КОМАНДЫ
                                        </button>

                                        <button
                                            className={activeTab === 3 ? 'chosen_game_socials_button active_tab' : 'chosen_game_socials_button'}
                                            onClick={() => setActiveTab(3)}
                                        >
                                            РАСПИСАНИЕ
                                        </button>

                                        {/* <button
                                            className={activeTab === 4 ? 'chosen_game_socials_button active_tab' : 'chosen_game_socials_button'}
                                            onClick={() => setActiveTab(4)}
                                        >
                                            ДОП ИНФОРМАЦИЯ
                                        </button> */}
                                        {/* <button
                                            className={activeTab === 5 ? 'chosen_game_socials_button active_tab' : 'chosen_game_socials_button'}
                                            onClick={() => setActiveTab(5)}
                                        >
                                            ПЕРСОНАЛ ТУРНИРА
                                        </button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <CustomSpinner1 />
                    )}
                </div>
                <div>
                    {activeTab === 0 && (
                        <div className='chosen_tournament_info_tab_container'>
                            {
                                fetchingTournament ? (
                                    <CustomSpinner1 />
                                ) : (
                                    <>
                                        {(tournamentInfo?.statusName === "Cancelled" || tournamentInfo?.statusName === "Finished") && tournamentId ? (
                                            <div className='chosen_tournament_results'>
                                                <ResultsTable tournamentId={tournamentId} />
                                            </div>
                                        ) : (
                                            <>
                                                <div className='chosen_tournament_main_infoDescription'>
                                                    <div className='chosen_tournament_title'>
                                                        <h3>
                                                            О турнире:
                                                            <p> {tournamentInfo?.description} </p>
                                                        </h3>
                                                        <h3>
                                                            Дата регистрации:
                                                            <p>{formatDate(tournamentInfo?.regDateStart)} - {formatDate(tournamentInfo?.regDateEnd)}</p>
                                                        </h3>
                                                        <h3>
                                                            Дата проведения:
                                                            <p>{formatDate(tournamentInfo?.startDate)} - {formatDate(tournamentInfo?.endDate)}</p>
                                                        </h3>
                                                    </div>

                                                    <div className='chosen_tournament_titleInfo'>
                                                        <h3>Место проведения:<p> {tournamentInfo?.location} </p></h3>
                                                        <h3>Призовой фонд: <p>{tournamentInfo?.prizePool} ₽</p></h3>
                                                        <h3>Организаторы: <p>{tournamentInfo?.organisatorName}</p></h3>
                                                    </div>
                                                </div>
                                                {tournamentInfo?.statusName === "WaitForApprove" || tournamentInfo?.statusName === "Approved" || tournamentInfo?.statusName === "Registration" && (
                                                    <div className='chosen_tournament_main_info_slots'>
                                                        <ProgressBar max={tournamentInfo?.maxSlots} now={tournamentInfo?.activeSlots} />
                                                        <div className='chosen_tournament_main_info_slots_text'>
                                                            <div>{reservedSlots}/</div>
                                                            <div> {tournamentInfo?.maxSlots} СВОБОДНО</div>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </>
                                )
                            }
                        </div>
                    )}

                    {activeTab === 1 && (
                        <div className='chosen_tournament_info_tab_container'>
                            {
                                tournamentInfo?.bracket
                                    ? <TournamentBracket bracketData={JSON.parse(tournamentInfo?.bracket)} isAdmin={false} editMode={false} playerTeamId={`${player?.getTeamByGameTypeId(tournamentInfo.gameTypeId)?.teamId}`} />
                                    : <CustomSpinner1 sizeSpinnerContainer='300px' size='140px' />
                            }
                        </div>
                    )}

                    {activeTab === 2 && (
                        <div className='chosen_tournament_info_tab_container'>
                            {tournamentInfo?.tournamentTeams.length !== 0 ? (
                                <div className='chosen_tournament_teams_layer'>
                                    {
                                        tournamentInfo?.tournamentTeams.map(team => (
                                            <TeamItem key={team.teamId} tournamentTeam={team} />
                                        ))
                                    }
                                </div>
                            ) : (
                                <i>Еще нет зарегистрировавшихся на Турнир команд.</i>
                            )}
                        </div>
                    )}

                    {activeTab === 3 && (
                        <div className='chosen_tournament_info_tab_container'>
                            <div className='tournament_timetable_container'>
                                <div className='tournament_timetable_header'>
                                    Расписание матчей
                                </div>
                                <div className='tournament_timetable_hours'>
                                    Игровой день:
                                    с {convertTimeToLocal(tournamentInfo?.startMatchHour ?? 0)} до {convertTimeToLocal(tournamentInfo?.endMatchHour ?? 0)}
                                </div>
                                {tournamentInfo?.fastCupFlag && (
                                    <div className='tournament_timetable_fastcup'>
                                        <ExclamationDiamondFill size={20} /> Турнир FAST CUP - время матчей указано приблизительно, матчи следуют друг за другом
                                    </div>
                                )}
                                {tournamentInfo && tournamentInfo?.tournamentMatches.length !== 0 ? (
                                    <MatchesTimetable tournamentInfo={tournamentInfo} />
                                ) : (
                                    <i>Расписание будет доступно после окончания регистрации</i>
                                )}
                            </div>
                        </div>
                    )}

                    {activeTab === 5 && (
                        <div className='chosen_tournament_info_tab_container staff'>
                            <div className='chosen_tournament_main_infoDescription'>
                                {isTournamentAdditionalSlots() === 0 ? (
                                    <div>В турнире нет персонала</div>
                                ) : (
                                    <div className='chosen_tournament_info_staffContainer'>
                                        {tournamentInfo?.playerAdmins.length ? (
                                            <div className='chosen_tournament_info_staff admins'>
                                                <h5 className='chosen_tournament_info_staffTitle'>
                                                    Админы
                                                </h5>
                                                {tournamentInfo?.playerAdmins.map(a => (
                                                    <div className='chosen_tournament_info_staffMember' key={a.playerId}>
                                                        <img src={`${process.env.REACT_APP_IMG_STORAGE_URL}${a.avararUrl}`} alt={a.playerName} />
                                                        <div>{a.playerName}</div>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <></>
                                        )}

                                        {tournamentInfo?.observers.length ? (
                                            <div className='chosen_tournament_info_staff observers'>
                                                <h5 className='chosen_tournament_info_staffTitle'>
                                                    Обсерверы
                                                </h5>
                                                {tournamentInfo?.observers.map((o, idx) => (
                                                    <div className='chosen_tournament_info_staffMember' key={idx}>
                                                        <img src={`${process.env.REACT_APP_IMG_STORAGE_URL}${o.avararUrl}`} alt={o.playerName} />
                                                        <div>{o.playerName}</div>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <></>
                                        )}

                                        {tournamentInfo?.streamers.length ? (
                                            <div className='chosen_tournament_info_staff streamers'>
                                                <h5 className='chosen_tournament_info_staffTitle'>
                                                    Стримеры
                                                </h5>
                                                {tournamentInfo?.streamers.map((s, idx) => (
                                                    <div className='chosen_tournament_info_staffMember' key={idx}>
                                                        <img src={`${process.env.REACT_APP_IMG_STORAGE_URL}${s.avararUrl}`} alt={s.playerName} />
                                                        <div>{s.playerName}</div>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                {tournamentInfo?.statusName === "WaitForApprove" || tournamentInfo?.statusName === "Approved" || tournamentInfo?.statusName === "Registration" && (
                    <div className="participate_tournament_button_container">
                        {participateComment === 'ПРИНЯТЬ УЧАСТИЕ' ? (
                            <GooeyButton onClick={handleParticipate} isLoading={isLoading} disabled={isLoading}>
                                ПРИНЯТЬ УЧАСТИЕ
                            </GooeyButton>
                        ) : participateComment === 'Создайте команду для участия в турнире' ? (
                            <button className="save_tournament_button" onClick={() => navigate(`/player/${player?.playerId}`)}>СОЗДАЙТЕ КОМАНДУ ДЛЯ УЧАСТИЯ В ТУРНИРЕ</button>
                        ) : participateComment === 'НАЧАТЬ ТУРНИР' ? (
                            !tournamentStarted ? (
                                <GooeyButton onClick={startScheduler} isLoading={isLoading} disabled={isLoading}>
                                    НАЧАТЬ ТУРНИР
                                </GooeyButton>
                            ) : (
                                <div className="no_slots_text">Турнир начат</div>
                            )
                        ) : (
                            <div className="no_slots_text">{participateComment}</div>
                        )}
                    </div>
                )}
            </div>
        </Container>
    );
});

export default ChosenTournament;
import './RobinBracket.css';

import { useEffect, useState } from "react";

import { IBracketData } from "../../interfaces/IBracketData";

import BracketMatch from "../BracketMatch/BracketMatch";
import BracketResultsTable from "../ResultsTable/BracketResultsTable";
import {useEditMode, usePlayerTeam} from "../../providers/TournamentBracketContext";
import {IMatch} from "../../interfaces/IMatch";
import {IParticipant} from "../../interfaces/IParticipant";

interface RobinBracketProps {
    bracketData: IBracketData;
    playerTeamId: string | null;
    editMode: boolean;
}

const RobinBracket: React.FC<RobinBracketProps> = ({ bracketData, playerTeamId, editMode }): React.JSX.Element => {
    const [currentGroupId, setCurrentGroupId] = useState<number>(bracketData.group[0].id);
    const { setPlayerTeamId } = usePlayerTeam();
    const { setEditMode } = useEditMode();
    
    useEffect(() => {
        setEditMode(editMode);
        setPlayerTeamId(playerTeamId);
    }, [playerTeamId]);
    
    const renderMatchesByRoundId = (roundId: string | number): React.JSX.Element => {
        let currentRoundMatches: IMatch[] = bracketData.match.filter(m => m.round_id === roundId);

        return (
            <>
                {currentRoundMatches.map((m: IMatch, idx: number): React.JSX.Element => {
                    let bracketType: string | undefined = bracketData.stage.find(s => s.id === m.stage_id)?.type;
                    
                    return (
                        <div className="RoundRobinBracket_matchContainer">
                            <BracketMatch 
                                match={m}
                                bracketType={bracketType}
                            />
                        </div>
                    )
                })}
            </>
        );
    };
    
    const renderRoundsByGroupId = (groupId: string | number): React.JSX.Element => {
        return (
            <>
                {bracketData.round.filter(r => r.group_id === groupId).map(r => (
                    <div className="RoundRobinBracket_round">
                        <div className="RoundRobinBracket_roundTitle">
                            Round {r.id}
                        </div>
                        {renderMatchesByRoundId(r.id)}
                    </div>
                ))}
            </>
        );
    };
    
    const renderCurrentGroup = (): React.JSX.Element => {
        return (
            <div className="RoundRobinBracket_goup">
                {renderRoundsByGroupId(currentGroupId)}
            </div>
        );
    };
    
    const renderGroupsList = (): React.JSX.Element => {
        return (
            <div className="groupsList">
                {bracketData.group.length > 1 && (
                    <>
                        {bracketData.group.map(g => (
                            <div 
                                className={`groupsListItem ${currentGroupId === g.id ? 'active' : ''}`}
                                onClick={() => setCurrentGroupId(g.id)} key={g.id}
                            >
                                Группа {g.number}
                            </div>
                        ))}
                    </>
                )}
            </div>
        );
    };
    
    return (
        <div className="RoundRobinBracket_container">
            {renderGroupsList()}
            {renderCurrentGroup()}
            <BracketResultsTable participants={bracketData.participant} matches={bracketData.match} />
        </div>
    );
};

export default RobinBracket;
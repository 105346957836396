import { Context, createContext, useContext, useState, useEffect } from 'react';
import { IBracketData } from '../interfaces/IBracketData';
import { IMatch } from "../interfaces/IMatch";
import { CommonConstants } from "../helpers/Constants";
import { updateTournamentBracket } from "../api/updateTournamentBracket";
import { IStage } from "../interfaces/IStage";

interface HoveredMatchData {
    matchForWinner: string | null;
    matchForLoser: string | null;
}

interface TournamentBracketContextProps {
    hoveredTeamId: string | null;
    setHoveredTeamId: (id: string | null) => void;

    playerTeamId: string | null;
    setPlayerTeamId: (id: string | null) => void;

    tournamentId: string | null;
    setTournamentId: (id: string | null) => void;

    hoveredMatchData: HoveredMatchData;
    setHoveredMatchData: (data: HoveredMatchData) => void;

    hoveredOpponentId: string | null;
    setHoveredOpponentId: (id: string | null) => void;

    contextBracketData: IBracketData | null;
    setContextBracketData: (data: IBracketData | null) => void;

    isSwapOpponents: boolean;
    setSwapOpponents: (isSwapOpponents: boolean) => void;

    isEditMode: boolean;
    setEditMode: (isEditMode: boolean) => void;

    originalBracketData: IBracketData | null;
    setOriginalBracketData: (data: IBracketData | null) => void;

    changedMatches: IMatch[];
    setChangedMatches: React.Dispatch<React.SetStateAction<IMatch[]>>;

    activeMatchId: string | null;
    setActiveMatchId: (id: string | null) => void;

    isLoading: boolean;
    setLoading: (isLoading: boolean) => void;
}

const TournamentBracketContext: Context<TournamentBracketContextProps | undefined> = createContext<TournamentBracketContextProps | undefined>(undefined);

export const TournamentBracketContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [hoveredTeamId, setHoveredTeamId] = useState<string | null>(null);
    const [playerTeamId, setPlayerTeamId] = useState<string | null>(null);
    const [tournamentId, setTournamentId] = useState<string | null>(null);
    const [hoveredOpponentId, setHoveredOpponentId] = useState<string | null>(null);
    const [contextBracketData, setContextBracketData] = useState<IBracketData | null>(null);
    const [originalBracketData, setOriginalBracketData] = useState<IBracketData | null>(null);
    const [isSwapOpponents, setSwapOpponents] = useState<boolean>(false);
    const [isEditMode, setEditMode] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [activeMatchId, setActiveMatchId] = useState<string | null>(null);
    const [hoveredMatchData, setHoveredMatchData] = useState<HoveredMatchData>({
        matchForWinner: null,
        matchForLoser: null,
    });
    const [changedMatches, setChangedMatches] = useState<IMatch[]>([]);

    return (
        <TournamentBracketContext.Provider value={{
            hoveredTeamId,
            setHoveredTeamId,
            playerTeamId,
            setPlayerTeamId,
            tournamentId,
            setTournamentId,
            hoveredMatchData,
            setHoveredMatchData,
            hoveredOpponentId,
            setHoveredOpponentId,
            contextBracketData,
            setContextBracketData,
            isSwapOpponents,
            setSwapOpponents,
            isEditMode,
            setEditMode,
            originalBracketData,
            setOriginalBracketData,
            changedMatches,
            setChangedMatches,
            activeMatchId,
            setActiveMatchId,
            isLoading,
            setLoading
        }}>
            {children}
        </TournamentBracketContext.Provider>
    );
};

export const useMatchHover = () => {
    const context = useContext(TournamentBracketContext);
    if (context === undefined) {
        throw new Error('useMatchHover must be used within a TournamentBracketContextProvider');
    }
    return {
        hoveredMatchData: context.hoveredMatchData,
        setHoveredMatchData: context.setHoveredMatchData,
    };
};

export const useOpponentHover = () => {
    const context = useContext(TournamentBracketContext);
    if (context === undefined) {
        throw new Error('useOpponentHover must be used within a TournamentBracketContextProvider');
    }
    return {
        hoveredOpponentId: context.hoveredOpponentId,
        setHoveredOpponentId: context.setHoveredOpponentId,
    };
};

export const usePlayerTeam = () => {
    const context = useContext(TournamentBracketContext);

    if (context === undefined) {
        throw new Error('usePlayerTeam must be used within a TournamentBracketContextProvider');
    }
    return {
        playerTeamId: context.playerTeamId,
        setPlayerTeamId: context.setPlayerTeamId,
    };
};

export const useActiveMatch = () => {
    const context = useContext(TournamentBracketContext);
    if (context === undefined) {
        throw new Error('useActiveMatch must be used within a TournamentBracketContextProvider');
    }
    return {
        activeMatchId: context.activeMatchId,
        setActiveMatchId: context.setActiveMatchId,
    };
};

export const useIsSwapOpponents = () => {
    const context = useContext(TournamentBracketContext);

    if (context === undefined) {
        throw new Error('useIsSwapOpponents must be used within a TournamentBracketContextProvider');
    }

    return {
        isSwapOpponents: context.isSwapOpponents,
        setIsSwapOpponents: context.setSwapOpponents,
    };
};

export const useBracketData = (initialBracketData?: IBracketData) => {
    const context = useContext(TournamentBracketContext);

    if (context === undefined) {
        throw new Error('useBracketData must be used within a TournamentBracketContextProvider');
    }

    const { contextBracketData, setContextBracketData } = context;

    useEffect(() => {
        if (initialBracketData) {
            setContextBracketData(initialBracketData);
        }
    }, [initialBracketData, setContextBracketData]);

    return { contextBracketData, setContextBracketData };
};

export const useEditMode = () => {
    const context = useContext(TournamentBracketContext);

    if (context === undefined) {
        throw new Error('useEditMode must be used within a TournamentBracketContextProvider');
    }

    return {
        isEditMode: context.isEditMode,
        setEditMode: context.setEditMode,
    };
};

export const useLoading = () => {
    const context = useContext(TournamentBracketContext);

    if (context === undefined) {
        throw new Error('useEditMode must be used within a TournamentBracketContextProvider');
    }

    return {
        isLoading: context.isLoading,
        setLoading: context.setLoading,
    };
};

export const useOriginalBracketData = (initialBracketData?: IBracketData) => {
    const context = useContext(TournamentBracketContext);

    if (context === undefined) {
        throw new Error('useBracketData must be used within a TournamentBracketContextProvider');
    }

    const { originalBracketData, setOriginalBracketData } = context;

    useEffect(() => {
        if (initialBracketData) {
            setOriginalBracketData(initialBracketData);
        }
    }, [initialBracketData, setOriginalBracketData]);

    return { originalBracketData, setOriginalBracketData };
};

export const useChangedMatches = () => {
    const context = useContext(TournamentBracketContext);

    if (context === undefined) {
        throw new Error('useChangedMatches must be used within a TournamentBracketContextProvider');
    }

    const { changedMatches, setChangedMatches } = context;

    const addChangedMatch = (match: IMatch) => {
        setChangedMatches((prevMatches) => {
            const exists = prevMatches.some((m) => m.id === match.id);
            if (exists) {
                return prevMatches.map((m) => (m.id === match.id ? match : m));
            }
            return [...prevMatches, match];
        });
    };

    const clearChangedMatches = () => {
        setChangedMatches([]);
    };

    return { changedMatches, addChangedMatch, clearChangedMatches };
};

export const useEditTournamentBracket = () => {
    const { contextBracketData, setContextBracketData } = useBracketData();
    const { isEditMode } = useEditMode();
    const { originalBracketData } = useOriginalBracketData();
    const { addChangedMatch, clearChangedMatches, changedMatches } = useChangedMatches();
    const { setLoading } = useLoading();
    const { setOriginalBracketData } = useOriginalBracketData();
    const { setEditMode } = useEditMode();

    const updateMatchesByResultCurrentMatch = (
        currentMatch: IMatch,
        winnerId: string | null,
        loserId: string | null,
        stageType: string | undefined
    ) => {
        if (contextBracketData) {
            if (winnerId && loserId) {
                if (currentMatch.match_for_winner && currentMatch.match_for_winner !== CommonConstants.GuidEmpty) {
                    const nextMatch: IMatch | undefined = contextBracketData.match.find(
                        (m: IMatch): boolean => m.id === currentMatch.match_for_winner
                    );

                    if (nextMatch) {
                        if (currentMatch.match_type === 3) {
                            if (nextMatch.opponent2?.id === null) {
                                nextMatch.opponent2 = { ...nextMatch.opponent2, id: winnerId };
                            } else {
                                nextMatch.opponent1 = { ...nextMatch.opponent1, id: winnerId };
                            }
                        } else if (currentMatch.match_type === 1 || currentMatch.match_type === 0) {
                            if (currentMatch.number % 2 === 0) {
                                if (nextMatch.opponent2?.id === null) {
                                    nextMatch.opponent2 = { ...nextMatch.opponent2, id: winnerId };
                                } else {
                                    nextMatch.opponent1 = { ...nextMatch.opponent1, id: winnerId };
                                }
                            } else {
                                if (nextMatch.opponent1?.id === null) {
                                    nextMatch.opponent1 = { ...nextMatch.opponent1, id: winnerId };
                                } else {
                                    nextMatch.opponent2 = { ...nextMatch.opponent2, id: winnerId };
                                }
                            }
                        } else if (currentMatch.match_type === 2) {
                            let prevMatches: IMatch[] = contextBracketData.match.filter(match => match.match_for_winner === nextMatch.id);

                            if (prevMatches.length === 2) {
                                if (currentMatch.number % 2 === 0) {
                                    if (nextMatch.opponent2?.id === null) {
                                        nextMatch.opponent2 = { ...nextMatch.opponent2, id: winnerId };
                                    } else {
                                        nextMatch.opponent1 = { ...nextMatch.opponent1, id: winnerId };
                                    }
                                } else {
                                    if (nextMatch.opponent1?.id === null) {
                                        nextMatch.opponent1 = { ...nextMatch.opponent1, id: winnerId };
                                    } else {
                                        nextMatch.opponent2 = { ...nextMatch.opponent2, id: winnerId };
                                    }
                                }
                            } else if (prevMatches.length === 1) {
                                if (nextMatch.opponent2?.id === null) {
                                    nextMatch.opponent2 = { ...nextMatch.opponent2, id: winnerId };
                                } else {
                                    nextMatch.opponent1 = { ...nextMatch.opponent1, id: winnerId };
                                }
                            }
                        }
                    }
                }

                if (currentMatch.match_for_looser && currentMatch.match_for_looser !== CommonConstants.GuidEmpty) {
                    const nextMatchForLoser: IMatch | undefined = contextBracketData.match.find(
                        (m: IMatch): boolean => m.id === currentMatch.match_for_looser
                    );

                    if (nextMatchForLoser) {
                        if (stageType! === 'single_elimination') {
                            if (currentMatch.number % 2 === 0) {
                                if (nextMatchForLoser.opponent2?.id === null) {
                                    nextMatchForLoser.opponent2 = { ...nextMatchForLoser.opponent2, id: loserId };
                                } else {
                                    nextMatchForLoser.opponent1 = { ...nextMatchForLoser.opponent2, id: loserId };
                                }
                            } else {
                                if (nextMatchForLoser.opponent1?.id === null) {
                                    nextMatchForLoser.opponent1 = { ...nextMatchForLoser.opponent1, id: loserId };
                                } else {
                                    nextMatchForLoser.opponent2 = { ...nextMatchForLoser.opponent2, id: loserId };
                                }
                            }
                        } else {
                            if (currentMatch.number % 2 === 0 && currentMatch.round_id === 1) {
                                if (nextMatchForLoser.opponent2?.id === null) {
                                    nextMatchForLoser.opponent2 = { ...nextMatchForLoser.opponent2, id: loserId };
                                } else {
                                    nextMatchForLoser.opponent1 = { ...nextMatchForLoser.opponent1, id: loserId };
                                }
                            } else {
                                if (nextMatchForLoser.opponent1?.id === null) {
                                    nextMatchForLoser.opponent1 = { ...nextMatchForLoser.opponent1, id: loserId };
                                } else {
                                    nextMatchForLoser.opponent2 = { ...nextMatchForLoser.opponent2, id: loserId };
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    const undoChanges = () => {
        setContextBracketData(originalBracketData);
        clearChangedMatches();
    };

    const swapOpponents = (
        matchId1: string,
        matchId2: string,
        opponentId1: string | null,
        opponentId2: string | null
    ): void => {
        if (!isEditMode || !contextBracketData || !contextBracketData.match) return;

        const updatedMatches: IMatch[] = contextBracketData.match.map((m) => ({
            ...m,
            opponent1: m.opponent1 ? { ...m.opponent1 } : null,
            opponent2: m.opponent2 ? { ...m.opponent2 } : null,
        }));

        const match1: IMatch | undefined = updatedMatches.find((m) => m.id === matchId1);
        const match2: IMatch | undefined = updatedMatches.find((m) => m.id === matchId2);

        if (match1 && match2 && opponentId1 && opponentId2 && match1.round_id === match2.round_id) {
            if (match1.opponent1?.id === opponentId1) {
                match1.opponent1.id = opponentId2;
            } else if (match1.opponent2?.id === opponentId1) {
                match1.opponent2.id = opponentId2;
            }

            if (match2.opponent1?.id === opponentId2) {
                match2.opponent1.id = opponentId1;
            } else if (match2.opponent2?.id === opponentId2) {
                match2.opponent2.id = opponentId1;
            }

            setContextBracketData({
                ...contextBracketData,
                match: updatedMatches,
            });

            addChangedMatch(match1);
            addChangedMatch(match2);
        }
    };

    const updateMatchScore = (
        matchId: string,
        opponent1Score: number,
        opponent2Score: number
    ): void => {
        let currentMatch: IMatch | undefined = contextBracketData?.match.find((m: IMatch): boolean => m.id === matchId);
        let stageType: string | undefined = contextBracketData?.stage.find((s: IStage): boolean => s.id === currentMatch!.stage_id)!.type;

        if (
            !isEditMode ||
            !contextBracketData ||
            !contextBracketData.match ||
            matchId === null
        ) {
            return;
        }

        const updatedMatches: IMatch[] = contextBracketData.match.map((match: IMatch): IMatch => {
            if (match.id === matchId) {
                let winnerId: string | null = null;
                let loserId: string | null = null;

                if (opponent1Score !== opponent2Score) {
                    if (opponent1Score > opponent2Score) {
                        winnerId = match.opponent1?.id || null;
                        loserId = match.opponent2?.id || null;
                    } else {
                        winnerId = match.opponent2?.id || null;
                        loserId = match.opponent1?.id || null;
                    }
                }

                const updatedMatch = {
                    ...match,
                    opponent1: match.opponent1
                        ? {
                            ...match.opponent1,
                            score: opponent1Score,
                            result:
                                opponent1Score > opponent2Score
                                    ? 'win'
                                    : opponent1Score < opponent2Score
                                        ? 'loss'
                                        : undefined,
                        }
                        : null,
                    opponent2: match.opponent2
                        ? {
                            ...match.opponent2,
                            score: opponent2Score,
                            result:
                                opponent2Score > opponent1Score
                                    ? 'win'
                                    : opponent2Score < opponent1Score
                                        ? 'loss'
                                        : undefined,
                        }
                        : null,
                };

                updateMatchesByResultCurrentMatch(match, winnerId, loserId, stageType);

                const updatedMatchForWinner: IMatch | undefined = contextBracketData.match.find((m) => m.id === updatedMatch.match_for_winner);
                const updatedMatchForLoser: IMatch | undefined = contextBracketData.match.find((m) => m.id === updatedMatch.match_for_looser);

                addChangedMatch(updatedMatch);

                if (opponent1Score !== opponent2Score) {
                    updatedMatchForWinner && addChangedMatch(updatedMatchForWinner);
                    updatedMatchForLoser && addChangedMatch(updatedMatchForLoser);
                }

                return updatedMatch;
            }

            return match;
        });

        setContextBracketData({
            ...contextBracketData,
            match: updatedMatches,
        });
    };

    const updateMatchResult = (
        matchId: string,
        opponentId: string
    ): void => {
        let currentMatch: IMatch | undefined = contextBracketData?.match.find(m => m.id === matchId);
        let stageType: string | undefined = contextBracketData?.stage.find(s => s.id === currentMatch!.stage_id)!.type;

        if (!isEditMode || !contextBracketData || !contextBracketData.match) return;

        if (!matchId || !opponentId || opponentId === CommonConstants.GuidEmpty) return;

        const updatedMatches: IMatch[] = contextBracketData.match.map((match) => {
            if (match.id === matchId) {
                if (!match.opponent1 || !match.opponent2 || match.opponent1.result || match.opponent2.result) return match;

                let winnerId: string | null = null;
                let loserId: string | null = null;

                if (match.opponent1.id === opponentId) {
                    winnerId = match.opponent1.id;
                    loserId = match.opponent2.id;
                } else if (match.opponent2.id === opponentId) {
                    winnerId = match.opponent2.id;
                    loserId = match.opponent1.id;
                } else {
                    return match;
                }

                const updatedMatch = {
                    ...match,
                    opponent1: match.opponent1
                        ? { ...match.opponent1, result: match.opponent1.id === winnerId ? 'win' : 'loss' }
                        : null,
                    opponent2: match.opponent2
                        ? { ...match.opponent2, result: match.opponent2.id === winnerId ? 'win' : 'loss' }
                        : null,
                };

                updateMatchesByResultCurrentMatch(match, winnerId, loserId, stageType);

                const updatedMatchForWinner: IMatch | undefined = contextBracketData.match.find((m) => m.id === updatedMatch.match_for_winner);
                const updatedMatchForLoser: IMatch | undefined = contextBracketData.match.find((m) => m.id === updatedMatch.match_for_looser);

                addChangedMatch(updatedMatch);
                updatedMatchForWinner && addChangedMatch(updatedMatchForWinner);
                updatedMatchForLoser && addChangedMatch(updatedMatchForLoser);

                return updatedMatch;
            }

            return match;
        });

        setContextBracketData({
            ...contextBracketData,
            match: updatedMatches,
        });
    };

    const updateMatchDateTime = (matchId: string, dateTime: string): void => {
        if (!isEditMode || !contextBracketData || !contextBracketData.match) return;

        const updatedMatches: IMatch[] = contextBracketData.match.map((match) => {
            if (match.id === matchId) {
                const updatedMatch = {
                    ...match,
                    date: dateTime,
                };

                addChangedMatch(updatedMatch);

                return updatedMatch;
            }

            return match;
        });

        setContextBracketData({
            ...contextBracketData,
            match: updatedMatches,
        });
    };

    const saveChanges = async (): Promise<void> => {
        const tournamentId: string | null | undefined = contextBracketData?.stage[0]?.tournament_id;
        setLoading(true);
        setEditMode(false);
        clearChangedMatches();

        if (tournamentId && changedMatches.length > 0) {
            updateTournamentBracket({ tournamentId: tournamentId, changedMatches: changedMatches })
                .then((newBracketData: IBracketData | null) => {
                    if (newBracketData) {
                        try {
                            setOriginalBracketData({ ...newBracketData });
                            setContextBracketData(newBracketData);
                        } catch (error) {
                            console.error(`Ошибка при парсинге JSON новой турнирной сетки: `, error);
                        }
                    }
                })
                .catch((error) => {
                    console.error('Ошибка обновления турнирной сетки: ', error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    return { swapOpponents, undoChanges, updateMatchScore, updateMatchResult, updateMatchDateTime, saveChanges };
};
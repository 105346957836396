import { useMatchHover, useOpponentHover } from "../providers/TournamentBracketContext";
import SteppedLineTo from "../components/SteppedLineTo/SteppedLineTo";
import { IMatch } from "../interfaces/IMatch";
import { IGroup } from "../interfaces/IGroup";
import { IRound } from "../interfaces/IRound";
import { useEffect } from "react";
import {CommonConstants} from "../helpers/Constants";

interface RenderLinesProps {
    bracketMatches: IMatch[];
    bracketGroups: IGroup[];
    bracketRounds: IRound[];
}

const RenderLines: React.FC<RenderLinesProps> = ({ bracketMatches, bracketRounds }: RenderLinesProps): React.JSX.Element => {
    const { hoveredOpponentId } = useOpponentHover();
    const { setHoveredMatchData } = useMatchHover();

    useEffect(() => {
        const updateHoveredMatchData = (m: IMatch, nextMatchForWinner: IMatch | undefined, nextMatchForLoser: IMatch | undefined) => {
            let isNextMatchInGroupById = nextMatchForWinner?.group_id === m.group_id;

            let isHoveredInCurrentMatch = !!hoveredOpponentId &&
                (m.opponent1?.id === hoveredOpponentId || m.opponent2?.id === hoveredOpponentId);

            let isHoveredInNextMatch = !!hoveredOpponentId &&
                (nextMatchForWinner?.opponent1?.id === hoveredOpponentId || nextMatchForWinner?.opponent2?.id === hoveredOpponentId);

            let isHoveredOpponentLossInCurrentMatch =
                (m.opponent1?.id === hoveredOpponentId && m.opponent1.result === 'loss') ||
                (m.opponent2?.id === hoveredOpponentId && m.opponent2.result === 'loss');

            if (isHoveredInCurrentMatch && !isHoveredInNextMatch && isNextMatchInGroupById && !isHoveredOpponentLossInCurrentMatch) {
                setHoveredMatchData({
                    matchForWinner: nextMatchForWinner ? nextMatchForWinner.id : null,
                    matchForLoser: nextMatchForLoser ? nextMatchForLoser.id : null
                });
            }
        };

        bracketMatches.forEach(m => {
            const nextMatchForWinner = bracketMatches.find(next => next.id === m.match_for_winner);
            const nextMatchForLoser = bracketMatches.find(next => next.id === m.match_for_looser);
            updateHoveredMatchData(m, nextMatchForWinner, nextMatchForLoser);
        });
    }, [hoveredOpponentId, bracketMatches, setHoveredMatchData]);

    const renderLines = (): React.JSX.Element[] => {
        const renderBaseLines = (): React.JSX.Element[] => {
            return bracketMatches
                .filter(m => m.match_for_winner)
                .map((m: IMatch, idx: number): React.JSX.Element | null => {
                    const nextMatch: IMatch | undefined = bracketMatches.find(next => next.id === m.match_for_winner);
                    const isNextMatchInGroupById: boolean = nextMatch?.group_id === m.group_id;
                    const countMatchesInNextRound = (): number => {
                        const nextMatchRounds: IRound[] = bracketRounds.filter(r => r.id === nextMatch?.group_id);
                        return nextMatchRounds.filter(r => r.group_id === nextMatch?.group_id && r.id === nextMatch.round_id).length;
                    };

                    if (isNextMatchInGroupById && countMatchesInNextRound() > 1) {
                        return (
                            <SteppedLineTo
                                key={`animated-${m.id}-${m.match_for_winner}-${m.match_for_looser}-${idx}`}
                                from={`match-${m.id}`}
                                to={`match-${m.match_for_winner}`}
                                borderColor="#7a7a80"
                                zIndex={10}
                                isStraightLine={true}
                            />
                        );
                    } else if (isNextMatchInGroupById) {
                        return (
                            <SteppedLineTo
                                key={`animated-${m.id}-${m.match_for_winner}-${m.match_for_looser}-${idx}`}
                                from={`match-${m.id}`}
                                to={`match-${m.match_for_winner}`}
                                borderColor="#7a7a80"
                                zIndex={10}
                            />
                        );
                    } else {
                        return null;
                    }
                })
                .filter(line => line !== null) as React.JSX.Element[];
        };

        const renderAnimateLines = (): React.JSX.Element[] => {
            if (hoveredOpponentId === CommonConstants.GuidEmpty) {
                return [];
            };

            return bracketMatches
                .filter(m => m.match_for_winner)
                .map((m: IMatch, idx: number): React.JSX.Element | null => {
                    const nextMatchForWinner: IMatch | undefined = bracketMatches.find(next => next.id === m.match_for_winner);
                    const isNextMatchInGroupById: boolean = nextMatchForWinner?.group_id === m.group_id;

                    const isHoveredInCurrentMatch: boolean | null = !!hoveredOpponentId &&
                        (m.opponent1?.id === hoveredOpponentId || m.opponent2?.id === hoveredOpponentId);

                    const isHoveredInNextMatch: boolean | null = !!hoveredOpponentId &&
                        (nextMatchForWinner?.opponent1?.id === hoveredOpponentId || nextMatchForWinner?.opponent2?.id === hoveredOpponentId);

                    const isHoveredOpponentLossInCurrentMatch: boolean =
                        m.opponent1?.id === hoveredOpponentId && m.opponent1.result === 'loss' ||
                        m.opponent2?.id === hoveredOpponentId && m.opponent2.result === 'loss';

                    if (
                        !isHoveredInNextMatch &&
                        isNextMatchInGroupById &&
                        isHoveredInCurrentMatch &&
                        !isHoveredOpponentLossInCurrentMatch
                    ) {
                        return (
                            <SteppedLineTo
                                key={`${idx}-${m.id}-${Math.floor(Math.random() * 10000)}`}
                                from={`match-${m.id}`}
                                to={`match-${m.match_for_winner}`}
                                borderColor="#45ffa6"
                                zIndex={10}
                                isFlash={true}
                            />
                        );
                    }

                    if (
                        isHoveredInCurrentMatch &&
                        isNextMatchInGroupById &&
                        !isHoveredOpponentLossInCurrentMatch
                    ) {
                        return (
                            <SteppedLineTo
                                key={`${idx}-${m.id}-${Math.floor(Math.random() * 10000)}`}
                                from={`match-${m.id}`}
                                to={`match-${m.match_for_winner}`}
                                borderColor="#45ffa6"
                                zIndex={10}
                                animate={true}
                            />
                        );
                    }

                    return null;
                })
                .filter(line => line !== null) as React.JSX.Element[];
        };

        return [...renderBaseLines(), ...renderAnimateLines()];
    };

    return (
        <div className="SingleBracket_container">
            {renderLines()}
        </div>
    );
};

export default RenderLines;
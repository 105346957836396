import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Globe2, Recycle } from 'react-bootstrap-icons';
import { redirect, useNavigate, useParams } from 'react-router-dom';
import starCaptain from '../../assets/img/LobbyIndicators/starСaptain.svg';
import { AppContext } from '../../components/AppContext';
import DOTA2BotControlViget from '../../components/DOTA2/DOTA2BotControlVidget/DOTA2BotControlViget';
import CS2Lobby from '../../components/Lobby/CS2Lobby';
import Dota2Lobby from '../../components/Lobby/Dota2Lobby';
import { TournamentConstants } from '../../helpers/constants/tournamentConstants';
import { IDota2BotStatus } from '../../models/dto/dota2/IDota2BotStatus';
import { ICS2LobbySettings } from '../../models/dto/lobby/CS2/ICS2LobbySettings';
import { ICS2LobbyState } from '../../models/dto/lobby/CS2/ICS2LobbyState';
import { IDota2LobbySettings } from '../../models/dto/lobby/Dota2/IDota2LobbySettings';
import { IDota2LobbyState } from '../../models/dto/lobby/Dota2/IDota2LobbyState';
import { ILobbyMemberBase } from '../../models/dto/lobby/LobbyBase/ILobbyMemberBase';
import { MemberTypeEnum } from '../../models/enums/MemberTypeEnum';
import './MapSelection.css';

const CardSelection: React.FC = observer(() => {
    const navigate = useNavigate();
    const { matchLobbyId, gameTypeId } = useParams<{ matchLobbyId: string, gameTypeId: string }>();
    const { player } = useContext(AppContext);
    const [connection, setConnection] = useState<HubConnection | null>(null);
    const [lobbyState, setLobbyState] = useState<ICS2LobbyState | IDota2LobbyState>();

    const lobbyStateRef = useRef<ICS2LobbyState | undefined>(undefined);
    const [lobbyAlive, setLobbyAlive] = useState(true);
    const [lobbySettings, setLobbySettings] = useState<ICS2LobbySettings | IDota2LobbySettings>();
    const INACTIVITY_TIMEOUT = 20000;
    const [userActive, setUserActive] = useState(true);

    let sortedPlayersTeamA: ILobbyMemberBase[] = [...(lobbyState?.teamA?.players || [])].sort((a, b) => {
        if (a.isCaptain === b.isCaptain) {
            return 0;
        }
        return a.isCaptain ? -1 : 1;
    });

    let sortedStandinsTeamA: ILobbyMemberBase[] = [...(lobbyState?.teamA?.standins || [])].sort((a, b) => {
        if (a.isCaptain === b.isCaptain) {
            return 0;
        }
        return a.isCaptain ? -1 : 1;
    });

    let sortedPlayersTeamB: ILobbyMemberBase[] = [...(lobbyState?.teamB?.players || [])].sort((a, b) => {
        if (a.isCaptain === b.isCaptain) {
            return 0;
        }
        return a.isCaptain ? -1 : 1;
    });

    let sortedStandinsTeamB: ILobbyMemberBase[] = [...(lobbyState?.teamB?.standins || [])].sort((a, b) => {
        if (a.isCaptain === b.isCaptain) {
            return 0;
        }
        return a.isCaptain ? -1 : 1;
    });

    const inactivityTimeout = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        let hubUrl = '';

        if (!userActive) {
            console.log("User inactive, not connecting to the hub.");
            return;
        }

        switch (gameTypeId) {
            case TournamentConstants.CS2.gameTypeId:
                hubUrl = `${process.env.REACT_APP_BACKEND_API_URL}cs2matchlobby/${matchLobbyId}`;
                break;
            case TournamentConstants.DOTA2.gameTypeId:
                hubUrl = `${process.env.REACT_APP_BACKEND_API_URL}dota2matchlobby/${matchLobbyId}`;
                break;
            default:
                return;
        }

        const connect: HubConnection = new HubConnectionBuilder()
            .withUrl(hubUrl)
            .withAutomaticReconnect([500, 500, 500])
            .build();

        setConnection(connect);

        if (connect) {
            connect.on("SetLobbyState", (lobbyState) => {
                console.log("LobbyState: \n", lobbyState)
                setLobbyState(lobbyState);
                lobbyStateRef.current = lobbyState;
            });
            connect.on("SetLobbySettings", (lobbySettings: any) => {
                console.log("LobbySettings: \n", lobbySettings)
                setLobbySettings(lobbySettings);
            });

            connect.on("CloseConnection", (matchId: string) => {
                navigate("/");
            })

            connect.start()
                .then(async () => {
                    let lobbyStateResult: ICS2LobbyState | IDota2LobbyState = await connect.invoke("GetLobbyState", matchLobbyId);
                    if (lobbyStateResult) {
                        let playerTeamA = lobbyStateResult.teamA?.players?.find(p => p.memberId === player?.playerId);
                        let playerTeamB = lobbyStateResult.teamB?.players?.find(p => p.memberId === player?.playerId);
                        let standinTeamA = lobbyStateResult?.teamA?.standins?.find(p => p.memberId === player?.playerId);
                        let standinTeamB = lobbyStateResult?.teamB?.standins?.find(p => p.memberId === player?.playerId);
                        let playerInOthers = lobbyStateResult.others.find(o => o.memberId === player?.playerId);
                        if (playerTeamA || playerTeamB) {
                            await connect.invoke("PlayerConnected", matchLobbyId, player?.playerId, player?.getTeamByGameTypeId(gameTypeId ?? '')?.teamId);
                        } else if (standinTeamA || standinTeamB) {
                            await connect.invoke("StandinConnected", matchLobbyId, player?.playerId)
                        } else if (playerInOthers) {
                            await connect.invoke("OtherMemberConnected", matchLobbyId, player?.playerId);
                        } else if (player?.isAdmin) {
                            setLobbyState(lobbyStateResult);
                            await connect.invoke("SuperAdminConnected", matchLobbyId, player?.playerId)
                        } else {
                            redirect("/");
                        }
                        window.addEventListener("beforeunload", () => { unloadHandler(connect) });
                    } else {
                        console.log("Cannot get lobby state!");
                    }
                })
                .catch(err => {
                    console.error(err);
                    setLobbyAlive(false);
                });
        } else {
            //TODO: редирект на AccessDenied
            navigate("/");
        }

        return () => {
            if (connect?.state === "Connected") {
                let playerTeamA = lobbyStateRef.current?.teamA?.players?.find(p => p.memberId === player?.playerId);
                let playerTeamB = lobbyStateRef.current?.teamB?.players?.find(p => p.memberId === player?.playerId);

                let standinTeamA = lobbyState?.teamA?.standins?.find(p => p.memberId === player?.playerId);
                let standinTeamB = lobbyState?.teamB?.standins?.find(p => p.memberId === player?.playerId);

                let playerInOthers = lobbyStateRef.current?.others.find(o => o.memberId === player?.playerId);

                if (playerTeamA || playerTeamB) {
                    connect.invoke("PlayerDisconnected", matchLobbyId, player?.playerId, player?.getTeamByGameTypeId(gameTypeId ?? '')?.teamId)
                } else if (standinTeamA || standinTeamB) {
                    connect.invoke("StandinDisconnected", matchLobbyId, player?.playerId)
                } else if (playerInOthers) {
                    connect.invoke("OtherMemberDisconnected", matchLobbyId, player?.playerId)
                } else if (player?.isAdmin) {
                    connect.invoke("SuperAdminDisconnected", matchLobbyId, player?.playerId)
                } else {
                    window.removeEventListener("beforeunload", () => { unloadHandler(connect) });
                    redirect("/");
                }
            }
            window.removeEventListener("beforeunload", () => { unloadHandler(connect) });
        };
    }, [matchLobbyId, player?.playerId, gameTypeId, userActive]);

    useEffect(() => {
        const resetInactivityTimer = () => {
            if (inactivityTimeout.current) {
                clearTimeout(inactivityTimeout.current);
            }
            setUserActive(true);
            inactivityTimeout.current = setTimeout(() => {
                setUserActive(false);
                if (connection?.state === "Connected") {
                    connection.stop();
                }
            }, INACTIVITY_TIMEOUT);
        };

        window.addEventListener("mousemove", resetInactivityTimer);
        window.addEventListener("keydown", resetInactivityTimer);
        window.addEventListener("touchstart", resetInactivityTimer);

        resetInactivityTimer();

        return () => {
            if (inactivityTimeout.current) {
                clearTimeout(inactivityTimeout.current);
            }
            window.removeEventListener("mousemove", resetInactivityTimer);
            window.removeEventListener("keydown", resetInactivityTimer);
            window.removeEventListener("touchstart", resetInactivityTimer);
        };
    }, [connection]);

    useEffect(() => {
        return () => {
            window.removeEventListener("beforeunload", () => { unloadHandler(connection) });
        };
    }, [matchLobbyId, player?.playerId, gameTypeId, userActive]);

    const unloadHandler = async (connect: HubConnection | null) => {
        if (connection?.state === "Connected") {
            let playerTeamA = lobbyState?.teamA?.players?.find(p => p.memberId === player?.playerId);
            let playerTeamB = lobbyState?.teamB?.players?.find(p => p.memberId === player?.playerId);

            let standinTeamA = lobbyState?.teamA?.standins?.find(p => p.memberId === player?.playerId);
            let standinTeamB = lobbyState?.teamB?.standins?.find(p => p.memberId === player?.playerId);

            let playerInOthers = lobbyState?.others.find(o => o.memberId === player?.playerId);

            if (playerTeamA || playerTeamB) {
                await connect?.invoke("PlayerDisconnected", matchLobbyId, player?.playerId, player?.getTeamByGameTypeId(gameTypeId ?? '')?.teamId)
            } else if (standinTeamA || standinTeamB) {
                await connect?.invoke("StandinDisconnected", matchLobbyId, player?.playerId)
            } else if (playerInOthers) {
                await connect?.invoke("OtherMemberDisconnected", matchLobbyId, player?.playerId)
            } else if (player?.isAdmin) {
                await connect?.invoke("SuperAdminDisconnected", matchLobbyId, player?.playerId)
            } else {
                console.log("Blocked!");
                window.removeEventListener("beforeunload", () => { unloadHandler(connect) });
            }
        }
        window.removeEventListener("beforeunload", () => { unloadHandler(connect) });
    };

    const handleReadyClick = async (isReady: boolean) => {
        if (connection != null) {
            let playerTeamA = lobbyState?.teamA?.players?.find(p => p.memberId === player?.playerId);
            let playerTeamB = lobbyState?.teamB?.players?.find(p => p.memberId === player?.playerId);

            let standinTeamA = lobbyState?.teamA?.standins?.find(p => p.memberId === player?.playerId);
            let standinTeamB = lobbyState?.teamB?.standins?.find(p => p.memberId === player?.playerId);

            let playerInOthers = lobbyState?.others.find(o => o.memberId === player?.playerId);

            if (isReady) {
                if (playerTeamA || playerTeamB) {
                    let allReadyResponse: boolean = await connection?.invoke("SetPlayerReady", matchLobbyId, player?.playerId, player?.getTeamByGameTypeId(gameTypeId ?? '')?.teamId) ?? false;
                    if (allReadyResponse) {
                    }
                } else if (standinTeamA || standinTeamB) {
                    let allReadyResponse: boolean = await connection?.invoke("SetStandinReady", matchLobbyId, player?.playerId) ?? false;
                    if (allReadyResponse) {
                    }
                } else if (playerInOthers) {
                    let allReadyResponse: boolean = await connection?.invoke("SetOtherMemberReady", matchLobbyId, player?.playerId) ?? false;
                    if (allReadyResponse) {
                    }
                }
            } else {
                if (playerTeamA || playerTeamB) {
                    connection?.invoke("SetPlayerNotReady", matchLobbyId, player?.playerId, player?.getTeamByGameTypeId(gameTypeId ?? '')?.teamId)
                        .catch((error) => { console.log(error); });
                } else if (standinTeamA || standinTeamB) {
                    connection?.invoke("SetStandinNotReady", matchLobbyId, player?.playerId)
                        .catch((error) => { console.log(error); });
                } else if (playerInOthers) {
                    connection?.invoke("SetOtherMemberNotReady", matchLobbyId, player?.playerId)
                        .catch((error) => { console.log(error); });
                }
            }
        }
    };

    const handleTestReloadLobbyClick = () => {
        if (connection != null) {
            connection.invoke("RecycleLobby", matchLobbyId);
        } else {
            console.log("Connection is null");
        }
    }

    const handleRebootBotLobbyClick = () => {
        if (connection != null) {
            connection.invoke("RebootLobbyBot", matchLobbyId)
        } else {
            console.log("Connection is null")
        }
    }

    const handleRestartDota2Bot = async () => {
        try {
            const success = await connection?.invoke<boolean>("RebootLobbyBot", matchLobbyId);
            if (success) {
                alert('Бот успешно перезапущен.');
            } else {
                alert('Не удалось перезапустить бота.');
            }
        } catch (error) {
            console.error('Ошибка при перезапуске бота:', error);
            alert('Произошла ошибка при перезапуске бота.');
        }
    };

    const handleStopDota2Bot = async () => {
        try {
            const success = await connection?.invoke<boolean>("StopLobbyBot", matchLobbyId);
            if (success) {
                alert('Бот успешно остановлен.');
            } else {
                alert('Не удалось остановить бота.');
            }
        } catch (error) {
            console.error('Ошибка при остановке бота:', error);
            alert('Произошла ошибка при остановке бота.');
        }
    };

    const handleCheckDota2BotStatus = async () => {
        try {
            const status = await connection?.invoke<IDota2BotStatus>("CheckLobbyBotStatus", matchLobbyId);
            if (status) {
                console.log(`Bot Status: ${status}`)
                alert(`Статус бота: ${status.isRunning ? 'Работает' : 'Не работает'}`);
            } else {
                alert('Не удалось получить статус бота.');
            }
        } catch (error) {
            console.error('Ошибка при проверке статуса бота:', error);
            alert('Произошла ошибка при проверке статуса бота.');
        }
    };

    function getReadyStateForCurrentMember(): boolean {
        let playerTeamA = lobbyState?.teamA?.players?.find(p => p.memberId === player?.playerId);
        let playerTeamB = lobbyState?.teamB?.players?.find(p => p.memberId === player?.playerId);

        let standinTeamA = lobbyState?.teamA?.standins?.find(p => p.memberId === player?.playerId);
        let standinTeamB = lobbyState?.teamB?.standins?.find(p => p.memberId === player?.playerId);
        let playerInOthers = lobbyState?.others.find(o => o.memberId === player?.playerId);

        if (playerTeamA) {
            return playerTeamA.isReady;
        } else if (playerTeamB) {
            return playerTeamB.isReady;
        }
        else if (standinTeamA) {
            return standinTeamA.isReady;
        }
        else if (standinTeamB) {
            return standinTeamB.isReady;
        }
        else if (playerInOthers) {
            return playerInOthers.isReady;
        }

        return false;
    }

    const renderLobbyComponent = () => {
        if (!gameTypeId || !lobbyState || !connection) return null;

        switch (gameTypeId) {
            case TournamentConstants.CS2.gameTypeId:
                return <CS2Lobby lobbyState={lobbyState as ICS2LobbyState} connection={connection} lobbySettings={lobbySettings as ICS2LobbySettings} />;
            case TournamentConstants.DOTA2.gameTypeId:
                return <Dota2Lobby lobbyState={lobbyState as IDota2LobbyState} connection={connection} />;
            default:
                return <div>Unknown game type</div>;
        }
    };

    const getOtherMember = (playerId: string) => lobbyState?.others.find(o => o.memberId === player?.playerId);

    return (
        <div className='mapSelection_container'>
            <div className='mapselection_tournamentname'>
                {lobbyState?.tournamentName}
            </div>
            {lobbyAlive ? (
                <>
                    {((getOtherMember(player?.playerId ?? '')?.memberType === MemberTypeEnum.Admin || player?.isAdmin) && gameTypeId === TournamentConstants.DOTA2.gameTypeId) && (
                        <DOTA2BotControlViget onCheckStatus={handleCheckDota2BotStatus} onStopBot={handleStopDota2Bot} onRestartBot={handleRestartDota2Bot} />
                    )}
                    <div className='mapSelection_mainContant'>
                        <div className='teams_container'>
                            <h3 className='teams_name' onClick={() => window.open(`/team/${lobbyState?.teamA?.teamId}`, '_blank')}>
                                {lobbyState?.teamA?.teamName}
                            </h3>
                            <div className='teams_players'>
                                {sortedPlayersTeamA.map((playerMember) => (
                                    <div className='teams_team'>
                                        <div className={`isConnected_container ${playerMember?.isConnected && userActive ? 'connected' : ''}`}>
                                            <Globe2 size={27} />
                                        </div>

                                        <div className={`teams_player_container ${playerMember?.isReady ? 'ready' : ''} firstTeam`} key={`${playerMember.memberId}_container`}>
                                            <div className='teams_player_avatarContainer firstTeam'>
                                                <img className='teams_player_avatar' src={`${process.env.REACT_APP_IMG_STORAGE_URL}${playerMember.avatarUrl}`} alt='palyerAvatar' />
                                            </div>

                                            <div className='teams_player_nameContainer' onClick={() => window.open(`/player/${playerMember.memberId}`, '_blank')}>
                                                <h3 className='teams_player_name'>{playerMember.userName}</h3>
                                            </div>

                                            <div className='teams_player_isCaptainContainer'>
                                                {
                                                    playerMember.isCaptain && (<img src={starCaptain} alt='isCaptain' />)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))
                                }
                                {
                                    sortedStandinsTeamA.map((playerMember) => (
                                        <div className='teams_team'>
                                            <div className={`isConnected_container ${playerMember?.isConnected && userActive ? 'connected' : ''}`}>
                                                <Globe2 size={27} />
                                            </div>

                                            <div className={`teams_player_container ${playerMember?.isReady ? 'ready' : ''} firstTeam`} key={`${playerMember.memberId}_container`}>
                                                <div className='teams_player_avatarContainer firstTeam'>
                                                    <img className='teams_player_avatar' src={`${process.env.REACT_APP_IMG_STORAGE_URL}${playerMember.avatarUrl}`} alt='palyerAvatar' />
                                                </div>

                                                <div className='teams_player_nameContainer' onClick={() => window.open(`/player/${playerMember.memberId}`, '_blank')}>
                                                    <h3 className='teams_player_name'>{playerMember.userName}</h3>
                                                </div>

                                                <div className='teams_player_isCaptainContainer'>
                                                    {
                                                        playerMember.isCaptain && (<img src={starCaptain} alt='isCaptain' />)
                                                    }
                                                </div>

                                                <div className='teams_team_standinsIcon_firstTeam'>Замена</div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                        {lobbyState?.readyStageDone ? (
                            renderLobbyComponent()
                        ) : (
                            <div className='mapselection_ready_buttonContainer'>
                                <button className={`mapselection_ready_button ${getReadyStateForCurrentMember() ? '' : 'ready'}`} onClick={() => handleReadyClick(!getReadyStateForCurrentMember())}>
                                    {getReadyStateForCurrentMember() ? 'НЕ ГОТОВ' : 'ГОТОВ'}
                                </button>
                            </div>
                        )}
                        <div className='teams_container'>
                            <h3 className='teams_name secondTeam' onClick={() => window.open(`/team/${lobbyState?.teamB?.teamId}`, '_blank')}>
                                {lobbyState?.teamB?.teamName}
                            </h3>
                            <div className='teams_players'>
                                {
                                    sortedPlayersTeamB.map((playerMember) => (
                                        <div className='teams_team'>
                                            <div className={`teams_player_container ${playerMember?.isReady ? 'ready' : ''}`} key={`${playerMember.memberId}_container`}>
                                                <div className='teams_player_avatarContainer'>
                                                    <img className='teams_player_avatar' src={`${process.env.REACT_APP_IMG_STORAGE_URL}${playerMember.avatarUrl}`} alt='palyerAvatar' />
                                                </div>

                                                <div className='teams_player_nameContainer' onClick={() => window.open(`/player/${playerMember.memberId}`, '_blank')}>
                                                    <h3 className='teams_player_name'>{playerMember.userName}</h3>
                                                </div>

                                                <div className='teams_player_isCaptainContainer'>
                                                    {
                                                        playerMember.isCaptain && (<img src={starCaptain} alt='isCaptain' />)
                                                    }
                                                </div>
                                            </div>

                                            <div className={`isConnected_container ${playerMember?.isConnected && userActive ? 'connected' : ''} secondTeam`}>
                                                <Globe2 size={27} />
                                            </div>
                                        </div>
                                    ))
                                }
                                {
                                    sortedStandinsTeamB.map((playerMember) => (
                                        <div className='teams_team'>
                                            <div className={`teams_player_container ${playerMember?.isReady ? 'ready' : ''}`} key={`${playerMember.memberId}_container`}>
                                                <div className='teams_player_avatarContainer'>
                                                    <img className='teams_player_avatar' src={`${process.env.REACT_APP_IMG_STORAGE_URL}${playerMember.avatarUrl}`} alt='palyerAvatar' />
                                                </div>

                                                <div className='teams_player_nameContainer' onClick={() => window.open(`/player/${playerMember.memberId}`, '_blank')}>
                                                    <h3 className='teams_player_name'>{playerMember.userName}</h3>
                                                </div>

                                                <div className='teams_player_isCaptainContainer'>
                                                    {
                                                        playerMember.isCaptain && (<img src={starCaptain} alt='isCaptain' />)
                                                    }
                                                </div>

                                                <div className='teams_team_standinsIcon_secondTeam'>Замена</div>
                                            </div>

                                            <div className={`isConnected_container ${playerMember?.isConnected && userActive ? 'connected' : ''} secondTeam`}>
                                                <Globe2 size={27} />
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        {player?.isAdmin && (
                            <button className='recylcleButton' onClick={() => handleTestReloadLobbyClick()}>
                                <Recycle /> RECYCLE LOBBY
                            </button>
                        )}
                    </div>

                    <div className='additional_lobby_members'>
                        {lobbyStateRef.current?.others.some(o => o.memberType === MemberTypeEnum.Streamer) && (
                            <div className='lobby_members'>
                                <h2 className='lobby_members_title'>Streamers</h2>

                                <div className='lobby_members_map'>
                                    {lobbyStateRef.current?.others.filter(m => m.memberType === MemberTypeEnum.Streamer).map(m => (
                                        <div className='lobby_memderContainer'>
                                            <div className={`lobby_memderImgContainer ${m.isReady ? 'ready' : ''}`}>
                                                <img alt={`${m.userName}`} className='lobby_memderImg' src={`${process.env.REACT_APP_IMG_STORAGE_URL}${m.avatarUrl}`} />
                                            </div>
                                            <div className='lobby_memderName'>{m.userName}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                        {lobbyStateRef.current?.others.some(m => m.memberType === MemberTypeEnum.Observer) && (
                            <div className='lobby_members'>
                                <h2 className='lobby_members_title'>Observers</h2>
                                <div className='lobby_members_map'>
                                    {lobbyStateRef.current?.others.filter(m => m.memberType === MemberTypeEnum.Observer).map(m => (
                                        <div className='lobby_memderContainer' key={m.memberId}>
                                            <div className={`lobby_memderImgContainer ${m.isReady ? 'ready' : ''}`}>
                                                <img alt={`${m.userName}`} className='lobby_memderImg' src={`${process.env.REACT_APP_IMG_STORAGE_URL}${m.avatarUrl}`} />
                                            </div>
                                            <div className='lobby_memderName'>{m.userName}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                        {lobbyStateRef.current?.others.some(m => m.memberType === MemberTypeEnum.Admin) && (
                            <div className='lobby_members'>
                                <h2 className='lobby_members_title'>Admins</h2>
                                <div className='lobby_members_map'>
                                    {lobbyStateRef.current?.others.filter(m => m.memberType === MemberTypeEnum.Admin).map(m => (
                                        <div className='lobby_memderContainer' key={m.memberId}>
                                            <div className={`lobby_memderImgContainer ${m.isReady ? 'ready' : ''}`}>
                                                <img alt={`${m.userName}`} className='lobby_memderImg' src={`${process.env.REACT_APP_IMG_STORAGE_URL}${m.avatarUrl}`} />
                                            </div>
                                            <div className='lobby_memderName'>{m.userName}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </>
            ) : (
                <>
                    <div className='old_lobby'>Данное лобби устарело</div>
                </>
            )}
        </div>
    );
});

export default CardSelection;
import './BracketMatch.css';

import React, { useEffect, useRef, useState } from 'react';

import { IMatch } from "../../interfaces/IMatch";
import { IParticipant } from '../../interfaces/IParticipant';
import { CommonConstants } from '../../helpers/Constants';

import BracketOpponent from '../BracketOpponent/BracketOpponent';
import {
    useEditMode,
    useEditTournamentBracket,
    useIsSwapOpponents,
    useMatchHover,
    useOpponentHover,
    useChangedMatches,
    useActiveMatch,
    useBracketData
} from "../../providers/TournamentBracketContext";
import { ArrowReturnRight } from "react-bootstrap-icons";
import BracketMatchUpdatedMenu from "../BracketMatchUpdatedMenu/BracketMatchUpdatedMenu";

interface BracketMatchProps {
    match: IMatch;
    isMatchWithArrow?: boolean;
    bracketType: string | undefined;
}

interface IOpponentNameAndTag {
    tag: string | null | undefined;
    name: string | null | undefined;
}

const BracketMatch: React.FC<BracketMatchProps> = ({ match, isMatchWithArrow, bracketType }) => {
    const { swapOpponents } = useEditTournamentBracket();
    const { hoveredMatchData } = useMatchHover();
    const { setIsSwapOpponents } = useIsSwapOpponents();
    const { isEditMode } = useEditMode();
    const { changedMatches } = useChangedMatches();
    const { hoveredOpponentId } = useOpponentHover();
    const { contextBracketData } = useBracketData();

    const [matchClassName, setMatchClassName] = useState<string>('');

    const [resultFirstTeam, setResultFirstTeam] = useState<string | number>('');
    const [resultSecondTeam, setResultSecondTeam] = useState<string | number>('');

    const [opponent1, setOpponent1] = useState<IParticipant | undefined>(undefined);
    const [opponent2, setOpponent2] = useState<IParticipant | undefined>(undefined);
    
    const [opponent1NameAndTag, setOpponent1NameAndTag] = useState<IOpponentNameAndTag>({ tag: opponent1?.tag, name: opponent1?.name });
    const [opponent2NameAndTag, setOpponent2NameAndTag] = useState<IOpponentNameAndTag>({ tag: opponent2?.tag, name: opponent2?.name });

    const [resultFirstTeamClassName, setResultFirstTeamClassName] = useState<'win' | 'loss' | 'default'>('default');
    const [resultSecondTeamClassName, setResultSecondTeamClassName] = useState<'win' | 'loss' | 'default'>('default');

    const [showMenu, setShowMenu] = useState<boolean>(false);

    const matchRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLDivElement>(null);

    const { activeMatchId, setActiveMatchId } = useActiveMatch();

    useEffect(() => {
        let participant1: IParticipant | undefined = match.opponent1?.id === CommonConstants.GuidEmpty
            ? {
                id: CommonConstants.GuidEmpty,
                tournament_id: '',
                name: null,
                tag: null
            }
            : contextBracketData?.participant.find(p => p.id === match.opponent1?.id);

        setOpponent1(participant1);

        let participant2: IParticipant | undefined = match.opponent2?.id === CommonConstants.GuidEmpty
            ? {
                id: CommonConstants.GuidEmpty,
                tournament_id: '',
                name: null,
                tag: null
            }
            : contextBracketData?.participant.find(p => p.id === match.opponent2?.id);
        setOpponent2(participant2);

    }, [match, contextBracketData?.participant])

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const matchEl = matchRef.current;
            const menuEl = menuRef.current;
            if (
                showMenu &&
                matchEl &&
                !matchEl.contains(event.target as Node) &&
                menuEl &&
                !menuEl.contains(event.target as Node)
            ) {
                setShowMenu(false);
                setActiveMatchId(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showMenu]);

    const handleMouseEnter = () => {
        if (isEditMode) {
            setActiveMatchId(match.id);
            setShowMenu(true);
        }
    };

    useEffect(() => {
        if (hoveredOpponentId !== CommonConstants.GuidEmpty) {
            if (hoveredMatchData.matchForWinner === match.id) {
                setMatchClassName('selectedMatch_winner');
            } else if (hoveredMatchData.matchForLoser === match.id) {
                setMatchClassName('selectedMatch_loser');
            } else {
                setMatchClassName('');
            }
        }
    }, [hoveredMatchData, match.id])

    useEffect(() => {
        const { opponent1, opponent2 } = match;

        if (opponent1 && opponent1.id !== CommonConstants.GuidEmpty) {
            if (!opponent2 || opponent2.id === CommonConstants.GuidEmpty) {
                setResultFirstTeamClassName('win');
            } else {
                if (opponent1.score && opponent2.score) {
                    if (opponent1.score > opponent2.score) {
                        setResultFirstTeamClassName('win');
                    } else if (opponent1.score < opponent2.score) {
                        setResultFirstTeamClassName('loss');
                    } else {
                        setResultFirstTeamClassName('default');
                    }
                } else if (opponent1.result && opponent2.result) {
                    if (opponent1.result === 'win') {
                        setResultFirstTeamClassName('win');
                    } else if (opponent1.result === 'loss') {
                        setResultFirstTeamClassName('loss');
                    } else {
                        setResultFirstTeamClassName('default');
                    }
                }
            }
        }

        if (opponent2 && opponent2.id !== CommonConstants.GuidEmpty) {
            if (!opponent1 || opponent1.id === CommonConstants.GuidEmpty) {
                setResultSecondTeamClassName('win');
            } else {
                if (opponent2.score && opponent1.score) {
                    if (opponent2.score > opponent1.score) {
                        setResultSecondTeamClassName('win');
                    } else if (opponent2.score === opponent1.score) {
                        setResultSecondTeamClassName('default');
                    } else if (opponent2.score < opponent1.score) {
                        setResultSecondTeamClassName('loss');
                    }
                } else if (opponent2.result && opponent1.result) {
                    if (opponent2.result === 'win') {
                        setResultSecondTeamClassName('win');
                    } else if (opponent1.result === 'draw') {
                        setResultSecondTeamClassName('default');
                    } else if (opponent2.result === 'loss') {
                        setResultSecondTeamClassName('loss');
                    }
                }
            }
        }
    }, [match.opponent1, match.opponent2]);

    useEffect(() => {
        if (!opponent1?.id) {
            setOpponent1NameAndTag({ tag: 'TBD', name: '' });
        } else if (opponent1!.id === CommonConstants.GuidEmpty) {
            setOpponent1NameAndTag({ tag: 'BYE', name: '' });
        } else if (opponent1 && opponent1.id !== CommonConstants.GuidEmpty) {
            setOpponent1NameAndTag({ tag: opponent1?.tag, name: opponent1.name });
        } else {
            setOpponent1NameAndTag({ tag: '-', name: '' });
        }

        if (!opponent2?.id) {
            setOpponent2NameAndTag({ tag: 'TBD', name: '' });
        } else if (opponent2.id === CommonConstants.GuidEmpty) {
            setOpponent2NameAndTag({ tag: 'BYE', name: '' });
        } else if (opponent2 && opponent2.id !== CommonConstants.GuidEmpty) {
            setOpponent2NameAndTag({ tag: opponent2?.tag, name: opponent2.name });
        } else {
            setOpponent2NameAndTag({ tag: '-', name: '' });
        }
    }, [opponent1, opponent2]);

    useEffect(() => {
        if (
            match.opponent1
            && (match.opponent1.score || match.opponent1?.score === 0)
            && match.opponent2
            && (match.opponent2.score || match.opponent2?.score === 0)
            && (match.match_type !== 3 && match.match_type !== 4)
        ) {
            setResultFirstTeam(match.opponent1.score);
            setResultSecondTeam(match.opponent2.score);
        } else if (match.opponent1 && match.opponent1.result && match.opponent2?.id === CommonConstants.GuidEmpty) {
            setResultFirstTeam(match.opponent1.result);
        } else if (match.opponent2 && match.opponent2.result && match.opponent1?.id === CommonConstants.GuidEmpty) {
            setResultSecondTeam(match.opponent2.result);
        } else if (match.opponent1 && match.opponent1.result && match.opponent2 && match.opponent2.result) {
            setResultFirstTeam(match.opponent1.result);
            setResultSecondTeam(match.opponent2.result);
        } else {
            setResultFirstTeam('');
            setResultSecondTeam('');
        }
    }, [match]);

    const isMatchHighlighted = (): string => {
        if (hoveredOpponentId && hoveredOpponentId !== CommonConstants.GuidEmpty) {
            if (match.opponent1?.id === hoveredOpponentId || match.opponent2?.id === hoveredOpponentId) {
                return 'selectedMatch'
            }
        }

        return '';
    }

    const onDropOpponent = (
        draggedId: string | number,
        targetId: string | number,
        sourceMatchId: string,
        targetMatchId: string
    ) => {
        if (isEditMode) {
            if (draggedId && targetId && sourceMatchId && targetMatchId) {
                if (draggedId !== targetId && sourceMatchId !== targetMatchId) {
                    swapOpponents(sourceMatchId, targetMatchId, String(draggedId), String(targetId));
                }
            }

        }

        setIsSwapOpponents(false);
    };

    const renderMatchDate = (): React.ReactElement => {
        if (!match.date) {
            return <></>;
        }

        const date = new Date(match.date).toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit', year: 'numeric' });
        const time = new Date(match.date).toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' });

        return (
            <div className='BracketMatch_date'>
                {date} {time}
            </div>
        );
    };

    const renderBracketMatchOpponents = (): React.ReactElement => {
        return (
            <>
                <BracketOpponent
                    opponentId={match.opponent1?.id}
                    opponentTag={opponent1NameAndTag.tag}
                    opponentName={opponent1NameAndTag.name}
                    resultOpponent={resultFirstTeam}
                    isLower={false}
                    resultTeamClassName={resultFirstTeamClassName}
                    matchType={match.match_type ?? null}
                    onDropOpponent={onDropOpponent}
                    matchId={match.id}
                    roundId={match.round_id}
                    bracketType={bracketType ?? ''}
                />

                <div className="BracketMatchLine"></div>

                <BracketOpponent
                    opponentId={match.opponent2?.id}
                    opponentTag={opponent2NameAndTag.tag}
                    opponentName={opponent2NameAndTag.name}
                    resultOpponent={resultSecondTeam}
                    isLower={true}
                    resultTeamClassName={resultSecondTeamClassName}
                    matchType={match.match_type ?? null}
                    onDropOpponent={onDropOpponent}
                    matchId={match.id}
                    roundId={match.round_id}
                    bracketType={bracketType ?? ''}
                />
            </>
        );
    };

    const renderBracketMatch = (): React.ReactElement => {
        return (
            <div
                className={`
                    BracketMatch 
                    ${isMatchHighlighted()} 
                    ${matchClassName}
                    ${changedMatches.some(m => m.id === match.id) ? 'changedMatch' : ''}
                `}
                ref={matchRef}
                onMouseEnter={handleMouseEnter}
                onClick={() => console.log(match.id)}
            >
                {(isMatchWithArrow && bracketType !== 'round_robin') && (
                    <ArrowReturnRight size={18} className={`BracketMatch_arrowReturnRightIcon ${matchClassName}`} />
                )}

                {(match.match_type === 3 && bracketType === 'double_elimination') && (
                    <ArrowReturnRight size={18} className='BracketMatch_arrowReturnRightUpIcon' />
                )}

                {renderBracketMatchOpponents()}
                {renderMatchDate()}

                {activeMatchId === match.id && showMenu && (
                    <BracketMatchUpdatedMenu
                        isEditMode={isEditMode}
                        showMenu={showMenu}
                        opponent1NameAndTag={opponent1NameAndTag}
                        opponent2NameAndTag={opponent2NameAndTag}
                        menuRef={menuRef}
                        matchId={match.id}
                        matchDate={match.date || ""}
                        opponent1={match.opponent1}
                        opponent2={match.opponent2}
                    />
                )}
            </div>
        )
    }

    return (
        <div>
            {renderBracketMatch()}
        </div>
    );
};

export default BracketMatch;
import './DoubleBracket.css';

import React, {useEffect, useState} from "react";
import {
    useBracketData, useChangedMatches,
    useEditMode, useEditTournamentBracket, useLoading,
    useOpponentHover,
    useOriginalBracketData,
    usePlayerTeam
} from "../../providers/TournamentBracketContext";

import { IBracketData } from "../../interfaces/IBracketData";
import { IRound } from "../../interfaces/IRound";
import { IMatch } from "../../interfaces/IMatch";
import { IParticipant } from "../../interfaces/IParticipant";

import BracketMatch from "../BracketMatch/BracketMatch";
import RenderLines from "../../common/RenderLines";
import { IGroup } from "../../interfaces/IGroup";
import { MatchTypeEnum } from "../../enums/MatchTypeEnum";
import CustomSpinner1 from "../../../CustomSpinners/CustomSpinner1";
import { CommonConstants } from '../../helpers/Constants';

interface DoubleBracketProps {
    bracketData: IBracketData;
    playerTeamId: string | null;
    editMode: boolean;
}

const DoubleBracket: React.FC<DoubleBracketProps> = ({ bracketData, playerTeamId, editMode }: DoubleBracketProps): React.JSX.Element => {
    const [currentBracketData , setCurrentBracketData ] = useState<IBracketData>(bracketData);
    const [isMount, setIsMount] = useState<boolean>(false);
    const { originalBracketData, setOriginalBracketData } = useOriginalBracketData();
    const { contextBracketData, setContextBracketData } = useBracketData();
    const { hoveredOpponentId } = useOpponentHover();
    const { setPlayerTeamId } = usePlayerTeam();
    const { isEditMode, setEditMode } = useEditMode();
    const { undoChanges, saveChanges } = useEditTournamentBracket();
    const { isLoading, setLoading } = useLoading();

    const { changedMatches } = useChangedMatches()
    useEffect(() => {
        console.log(changedMatches)
    }, [changedMatches])

    useEffect(() => {
        setLoading(true);
        setPlayerTeamId(playerTeamId);
        setContextBracketData(bracketData);
        setOriginalBracketData(JSON.parse(JSON.stringify(bracketData)));
        setTimeout(() => {
            setIsMount(true);
            setLoading(false);
        }, 30)
    }, [bracketData]);

    useEffect(() => {
        if (contextBracketData) {
            setCurrentBracketData(contextBracketData);
        }
    }, [contextBracketData, isLoading]);

    useEffect(() => {
        console.log(changedMatches)
    }, [changedMatches]);

    const renderMatchesByRoundId = (roundId: number): React.JSX.Element => {
        let currentRoundMatches: IMatch[] = currentBracketData.match.filter(m => m.round_id === roundId);

        return (
            <>
                {currentRoundMatches.map((m: IMatch, idx: number): React.JSX.Element => {
                    let bracketType: string | undefined = currentBracketData.stage.find(s => s.id === m.stage_id)?.type;
                    let isMatchHighlighted: boolean | null | string = hoveredOpponentId &&
                        (m.opponent1?.id === hoveredOpponentId || m.opponent2?.id === hoveredOpponentId);

                    const isMatchWithArrow: () => boolean = (): boolean => {
                        if (m.match_type === 3) {
                            return true;
                        }

                        let prevMatches = currentBracketData.match.filter(match => match.match_for_winner === m.id);

                        if (prevMatches.length === 2) {
                            if (prevMatches[0].group_id === prevMatches[1].group_id) {
                                return false;
                            }
                        }

                        if (m.match_type === 2) {
                            return true;
                        }

                        return false;
                    }

                    return (
                        <div
                            className={`SingleBracket_matchContainer ${isMatchHighlighted ? 'highlighted' : ''}`}
                            id={`match-${m.id}`}
                            key={m.id}
                        >
                            <BracketMatch
                                match={m}
                                isMatchWithArrow={isMatchWithArrow()}
                                bracketType={bracketType}
                            />
                        </div>
                    );
                })}
            </>
        );
    };

    const renderRoundsByGroupId = (groupId: number): React.JSX.Element => {
        return (
            <>
                {bracketData.round.filter(r => r.group_id === groupId).map((r: IRound, idx: number): React.JSX.Element => {
                    const countGroupHeight = (): number => {
                        let currentGroup: IGroup | undefined = currentBracketData.group.find(g => g.id === groupId);
                        let groupFirstRound: IRound = currentBracketData.round.filter(r => r.group_id === groupId)[0];
                        let countMatchesInFirstRound: number = currentBracketData.match.filter(m =>
                            m.round_id === groupFirstRound.id && m.group_id === currentGroup?.id
                        ).length;

                        return (countMatchesInFirstRound * 80) + 160;
                    }

                    return (
                        <div
                            className="DoubleBracket_round"
                            style={{height: countGroupHeight()}}
                            key={idx}
                        >
                            {renderMatchesByRoundId(r.id)}
                        </div>
                    )
                })}
            </>
        );
    };

    const renderRoundTitlesByGroupId = (groupId: number): React.JSX.Element => {
        const groupRounds = (): IRound[] => {
            let rounds: IRound[] = currentBracketData.round.filter(r => r.group_id === groupId);
            return rounds;
        }

        let currentRounds: IRound[] = groupRounds();

        let countRounds: number = groupRounds().length;

        if (groupId === currentBracketData.group[0].id) {
            let additionalRound = currentBracketData.round.find(r => r.group_id === currentBracketData.group[2].id);

            if (additionalRound) {
                currentRounds.push(additionalRound);
            }
        }

        return (
            <div className='DoubleBracket_group_roundsTitles'>
                {currentRounds.map((r) => {
                    let title: string;
                    let groupType: MatchTypeEnum | null | undefined = currentBracketData.match.find(m => m.group_id === r.group_id)?.match_type;

                    if (groupType === 4 || countRounds === 1) {
                        title = `Суперфинал`;
                    } else if (r.id === currentRounds[countRounds - 2]?.id) {
                        title = `Полуфинал`;
                    } else if (r.id === currentRounds[countRounds - 1]?.id) {
                        title = `Финал`;
                    } else {
                        if (groupType === 2) {
                            const countRoundsInUpperBracket: number = currentBracketData.round.filter(
                                r => r.group_id === currentBracketData.group[0].id
                            ).length;
                            title = `Раунд ${r.id - countRoundsInUpperBracket}`;
                        } else {
                            title = `Раунд ${r.id}`;
                        }
                    }

                    return (
                        <div className='DoubleBracket_group_roundsTitle' key={r.id}>
                            {title}
                        </div>
                    );
                })}
            </div>
        );
    };

    const renderGroupByGroupId = (groupId: number): React.JSX.Element => {
        return (
            <>
                {renderRoundsByGroupId(groupId)}
            </>
        );
    };

    const renderAdditionalBracket = (): React.JSX.Element | null => {
        if (!currentBracketData.group[2]) {
            return null;
        }

        const groupId = currentBracketData.group[2].id;
        const groupRounds: IRound[] = currentBracketData.round.filter(r => r.group_id === currentBracketData.group[2]?.id);

        if (!groupRounds.length || !groupId) {
            return null;
        }

        return (
            <div className="DoubleBracket_group">
                {renderGroupByGroupId(groupId)}
            </div>
        );
    };

    const renderUpperBracket = (): React.JSX.Element | null => {
        if (!currentBracketData.group[0]) {
            return null;
        }

        const groupId = currentBracketData.group[0].id;
        const groupRounds: IRound[] = currentBracketData.round.filter(r => r.group_id === groupId);

        if (!groupRounds.length) {
            return null;
        }

        return (
            <div className='DoubleBracket_group_wrapper'>
                <h3 className='DoubleBracket_groupTitle'>Верхняя сетка</h3>
                {renderRoundTitlesByGroupId(groupId)}
                <div className="DoubleBracket_group">
                    {renderGroupByGroupId(groupId)}
                    {renderAdditionalBracket()}
                </div>
            </div>
        );
    };

    const renderLowerBracket = (): React.JSX.Element | null => {
        if (!currentBracketData.group[1]) {
            return null;
        }

        const groupId = currentBracketData.group[1].id;
        const groupRounds: IRound[] = currentBracketData.round.filter(r => r.group_id === groupId);

        if (!groupRounds.length) {
            return null;
        }

        return (
            <div className='DoubleBracket_group_wrapper'>
                <h3 className='DoubleBracket_groupTitle'>Нижняя сетка</h3>
                {renderRoundTitlesByGroupId(groupId)}
                <div className="DoubleBracket_group">
                    {renderGroupByGroupId(currentBracketData.group[1].id)}
                </div>
            </div>
        );
    };

    const renderEditButtons = (): React.JSX.Element => {
        return (
            <>
                {editMode && (
                    <div className='DoubleBracket_editButtons'>
                        {isEditMode ? (
                            <>
                                <button onClick={() => {
                                    undoChanges();
                                    setEditMode(false);
                                    if (originalBracketData) {
                                        setCurrentBracketData(originalBracketData);
                                    }
                                }}>ОТМЕНА</button>

                                <button onClick={saveChanges}>СОХРАНИТЬ</button>
                            </>
                        ) : (
                            <button onClick={() => setEditMode(true)}>РЕДАКТИРОВАНИЕ</button>
                        )}
                    </div>
                )}
            </>
        )
    }

    const renderDoubleBracket = (): React.JSX.Element => {
        return (
            <div className="DoubleBracket_groups" >
                {isLoading ? (
                    <div className='DoubleBracket_groups_loaderContainer'>
                        <CustomSpinner1 sizeSpinnerContainer='300px' size='140px' />
                    </div>
                ) : (
                    <>
                        {renderEditButtons()}
                        {renderUpperBracket()}
                        {renderLowerBracket()}
                        {isMount && <RenderLines bracketMatches={currentBracketData.match} bracketGroups={currentBracketData.group} bracketRounds={currentBracketData.round} />}
                    </>
                )}
            </div>
        )
    };

    return (
        <div className="DoubleBracket_container">
            {renderDoubleBracket()}
        </div>
    );
};

export default DoubleBracket;
import './BracketResultsTable.css';

import React, { useEffect, useState } from "react";
import { usePlayerTeam } from "../../providers/TournamentBracketContext";

import { IMatch } from "../../interfaces/IMatch";
import { IParticipant } from "../../interfaces/IParticipant";
import { IBracketResultsTableParticipant } from "../../interfaces/IBracketResultsTableParticipant";

interface BracketResultsTableProps {
    participants: IParticipant[];
    matches: IMatch[]
}

const BracketResultsTable: React.FC<BracketResultsTableProps> = ({ participants, matches }: BracketResultsTableProps) => {
    const [tableData, setTableData] = useState<IBracketResultsTableParticipant[]>([]);
    const { playerTeamId } = usePlayerTeam();
    
    useEffect(() => {
        const newTableData = participants.map((p: IParticipant) => {
            const participantMatches = matches.filter(m => [m.opponent1?.id, m.opponent2?.id].includes(p.id));
            
            return {
                participantId: p.id ?? '',
                participantName: p.name,
                participantTag: p.tag,
                participantPts: countParticipantPts(p.id, participantMatches) ?? 0,
                participantWinMaps: countParticipantWinMaps(p.id, participantMatches) ?? 0,
                participantLossMaps: countParticipantLossMaps(p.id, participantMatches) ?? 0,
                participantDrawMaps: countParticipantDrawMaps(p.id, participantMatches) ?? 0,
                participantGames: countParticipantGames(participantMatches) ?? 0
            };
        });
        
        const sortedTableData = [...newTableData].sort((a, b) => b.participantPts - a.participantPts);
        setTableData(sortedTableData)
    }, [participants, matches]);
    
    const countParticipantPts = (currentParticipantId: string, participantMatches: IMatch[]): number => {
        let currentPts: number = 0;
        
        participantMatches.forEach((m: IMatch) => {
            if (m.opponent1 && m.opponent2) {
                if (m.opponent1?.id === currentParticipantId) {
                    m.opponent1.result === 'win' ? currentPts += 3 : m.opponent1.result === 'draw' ? currentPts += 1 : currentPts += 0;
                } else  {
                    m.opponent2.result === 'win' ? currentPts += 3 : m.opponent2.result === 'draw' ? currentPts += 1 : currentPts += 0;
                }
            }
        });
        
        return currentPts;
    } 
    
    const countParticipantWinMaps = (currentParticipantId: string, participantMatches: IMatch[]): number => {
        let currentWins = 0;
        
        participantMatches.forEach((m: IMatch) => {
            if (m.opponent1 && m.opponent2) {
                if (m.opponent1?.id === currentParticipantId) {
                    m.opponent1.result === 'win' ? currentWins += 1 : currentWins += 0;
                } else  {
                    m.opponent2.result === 'win' ? currentWins += 1 : currentWins += 0;
                }
            }
        });
        
        return currentWins;
    }
    
    const countParticipantLossMaps = (currentParticipantId: string, participantMatches: IMatch[]) => {
        let currentLosses: number = 0;
        
        participantMatches.forEach((m: IMatch) => {
            if (m.opponent1 && m.opponent2) {
                if (m.opponent1?.id === currentParticipantId) {
                    m.opponent1.result === 'loss' ? currentLosses += 1 : currentLosses += 0;
                } else  {
                    m.opponent2.result === 'loss' ? currentLosses += 1 : currentLosses += 0;
                }
            }
        });
        
        return currentLosses;
    }
    
    const countParticipantDrawMaps = (currentParticipantId: string, participantMatches: IMatch[]) => {
        let currentDraws: number = 0;
        
        participantMatches.forEach((m: IMatch) => {
            if (m.opponent1 && m.opponent2) {
                if (m.opponent1?.id === currentParticipantId) {
                    m.opponent1.result === 'draw' ? currentDraws += 1 : currentDraws += 0;
                } else  {
                    m.opponent2.result === 'draw' ? currentDraws += 1 : currentDraws += 0;
                }
            }
        });
        
        return currentDraws;
    } 
    
    const countParticipantGames = (participantMatches: IMatch[]): number => {
        return participantMatches.length;
    }
    
    const renderBracketResultsTable = (): React.ReactElement => {
        return (
            <div className="BracketResultsTable">
                <table className="results-table">
                    <thead>
                        <tr>
                            <th title='Место'>#</th>
                            <th title='Команда'>Team</th>
                            <th title='Кол-во игр'>G</th>
                            <th title='Кол-во побед'>W</th>
                            <th title='Кол-во ничьих'>D</th>
                            <th title='Кол-во поражений'>L</th>
                            <th title='Очки'>Pts</th>
                        </tr>
                    </thead>
                    
                    <tbody>
                        {tableData.map((row: IBracketResultsTableParticipant, idx: number): React.JSX.Element => (
                            <tr key={idx}>
                                <td data-label="#">{idx + 1}</td>
                                <td data-label="Team"
                                    className={`BracketResultsTable_team ${row.participantId === playerTeamId ? 'playerTeam' : ''}`}
                                    title={row.participantName ?? ''}
                                >{row.participantTag}</td>
                                <td data-label="G">{row.participantGames}</td>
                                <td data-label="W">{row.participantWinMaps}</td>
                                <td data-label="D">{row.participantDrawMaps}</td>
                                <td data-label="L">{row.participantLossMaps}</td>
                                <td data-label="Pts">{row.participantPts}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        )
    }

    return (
        <>
            {renderBracketResultsTable()}
        </>
    );
}

export default BracketResultsTable;
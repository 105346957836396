import { useEffect, useState } from "react";

import { IBracketData } from "./interfaces/IBracketData";
import { IParticipant } from "./interfaces/IParticipant";
import { IRound } from "./interfaces/IRound";
import { IMatch } from "./interfaces/IMatch";
import { IStage } from "./interfaces/IStage";
import { IGroup } from "./interfaces/IGroup";

import RobinBracket from "./components/RobinBracket/RobinBracket";
import SingleBracket from "./components/SingleBracket/SingleBracket";
import DoubleBracket from "./components/DoubleBracket/DoubleBracket";

import CustomSpinner1 from "../CustomSpinners/CustomSpinner1";
import { TournamentBracketContextProvider } from "./providers/TournamentBracketContext";

interface TournamentBracketProps {
    bracketData: IBracketData;
    editMode?: boolean;
    isAdmin?: boolean;
    playerTeamId: string | null;
}

const TournamentBracket: React.FC<TournamentBracketProps> = ({
                                                                 bracketData,
                                                                 editMode = false,
                                                                 isAdmin = false,
                                                                 playerTeamId
                                                             }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [participants, setParticipants] = useState<IParticipant[]>();
    const [rounds, setRounds] = useState<IRound[]>();
    const [matches, setMatches] = useState<IMatch[]>();
    const [stages, setStages] = useState<IStage[]>();
    const [groups, setGroups] = useState<IGroup[]>();

    useEffect(() => {
        if (bracketData) {
            setParticipants(bracketData.participant)
            setRounds(bracketData.round)
            setMatches(bracketData.match)
            setStages(bracketData.stage)
            setGroups(bracketData.group)
            setIsLoading(false);
        } else {
            setIsLoading(true);
        }
    }, [bracketData]);

    const renderBracketByType = (): React.JSX.Element[] | undefined => {
        if (
            !participants
            || !rounds
            || !matches
            || !stages
            || !groups
        ) {
            setIsLoading(true);
        } else {
            return stages.map(stage => {

                return (
                    <div className="TournamentBracket" style={{ width: '100%', cursor: 'default' }} key={stage.id}>
                        {stage.type === 'single_elimination' ? (
                            <SingleBracket bracketData={bracketData} playerTeamId={playerTeamId} editMode={editMode} />
                        ) : stage.type === 'double_elimination' ? (
                            <DoubleBracket bracketData={bracketData} playerTeamId={playerTeamId} editMode={editMode} />
                        ) : stage.type === 'round_robin' ? (
                            <RobinBracket bracketData={bracketData} playerTeamId={playerTeamId} editMode={editMode} />
                        ) : (
                            <div>Тут тип просто загрузка...</div>
                        )}
                    </div>
                );
            });
        }
    };

    return (
        <TournamentBracketContextProvider>
            {isLoading ? (
                <>
                    <CustomSpinner1 />
                </>
            ) : (
                <>
                    {renderBracketByType()}
                </>
            )}
        </TournamentBracketContextProvider>
    )
}

export default TournamentBracket;
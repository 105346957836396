import React, { useEffect, useState } from "react";
import './ManualTournament.css';
import { useParams } from "react-router-dom";
import { ITournamentInfoModel } from "../../../../../models/dto/tournament/ITournamentInfoModel";
import { getTournamentById } from "../../../../../http/tournament/tournamentActionsAPI";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import CustomSpinner1 from "../../../../../components/CustomSpinners/CustomSpinner1";
import MatchesTimetable from "../../../../../components/MatchesTimetable/MatchesTimetable";
import { ITournamentTeam } from "../../../../../models/dto/team/ITournamentTeam";
import { getPlayersByFilters } from "../../../../../http/playerActionsAPI";
import { IPlayerSelectorPlayerModel } from "../../../../../components/PlayerSelector/Models/IPlayerSelectorPlayerModel";
import { Search, X } from "react-bootstrap-icons";
import { ICommonTeamModel } from "../../../../../models/dto/team/ICommonTeamModel";
import {
    addPlayerToTeam, addTeamStandin, checkAndAddTeamToTournament,
    deleteTeamFromTournament, endTournament, forceAddTeamToTournament,
    getGameServersInfo,
    getTeamByTeamNamePart, getUpdateCheckerStatus, regenerateBracket, removePlayerFromTeam, removeTeamStandin,
    startRegistration, startSelectedGameServer, startUpdateChecker, stopRegistration,
    stopUpdateChecker,
    updateContainer
} from "../../../../../http/adminActionsAPI";
import { useModal } from "../../../../../hooks/useModal";
import MyAlert from "../../../../../components/MyAlert/MyAlert";
import ResultsTable from "../../../../../components/ResultsTable/ResultsTable";
import { ServersZonesEnum } from "../../../../../models/enums/server/ServersZonesEnum";
import { ISourceGameServerInfo } from "../../../../../models/dto/servers/ISourceGameServerInfo";
import TournamentBracket from "../../../../../components/TournamentBracket/TournamentBracket";

const ManualTournament: React.FC = () => {
    const [fetching, setFetching] = useState(false);
    const { tournamentId } = useParams()
    const [tournamentInfo, setTournamentInfo] = useState<ITournamentInfoModel>()
    const [editingTeamId, setEditingTeamId] = useState<string | null>(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredPlayers, setFilteredPlayers] = useState<IPlayerSelectorPlayerModel[] | null>([]);
    const [searchTeamQuery, setSearchTeamQuery] = useState('');
    const [filteredTeams, setFilteredTeams] = useState<ICommonTeamModel[] | null>([]);
    const [isAddingTeams, setIsAddingTeams] = useState(false);
    const [fetchResults, setFetchResults] = useState(false);
    const [serverZone, setServerZone] = useState<ServersZonesEnum>();
    const [serversInfo, setServersInfo] = useState<ISourceGameServerInfo[] | null>([]);
    const [matchIdToStartServer, setMatchIdToStartServer] = useState<string>('');
    const [updateCheckerStatus, setUpdateCheckerStatus] = useState<string>('');
    const [isWaiting, setIsLoading] = useState(false);

    const [serverMessage, setServerMessage] = useState<string>('');
    const [teamIdToForce, setTeamIdToForce] = useState<string>('');
    const { isVisible: showMyAlert, openModal: handleShowMyAlert, closeModal: handleCloseMyAlert } = useModal();
    const { isVisible: showTeamAlert, openModal: handleShowTeamAlert, closeModal: handleCloseTeamAlert } = useModal();

    const handleAsyncAction = async (action: () => Promise<any>) => {
        setIsLoading(true);
        try {
            await action();
        } finally {
            setIsLoading(false);
        }
    };

    const handleStartRegistration = () => handleAsyncAction(async () => {
        if (tournamentId) {
            const result = await startRegistration(tournamentId);
            setServerMessage(result === true ? 'Registration started successfully' : `Error starting registration: ${result}`);
            handleShowMyAlert();
        }
    });

    const handleStopRegistration = () => handleAsyncAction(async () => {
        if (tournamentId) {
            const result = await stopRegistration(tournamentId);
            setServerMessage(result === true ? 'Registration stopped and matches generated' : `Error stopping registration: ${result}`);
            handleShowMyAlert();
        }
    });

    const handleRegenerateBracket = () => handleAsyncAction(async () => {
        if (tournamentId) {
            const result = await regenerateBracket(tournamentId);
            if (result === true) {
                await fetchTournamentInfo();
            }
        }
    });

    const handleEditRoaster = (team: ITournamentTeam) => {
        if (!isWaiting) setEditingTeamId(team.teamId);
    };

    const handleAddPlayer = (teamId: string, playerId: string) => handleAsyncAction(async () => {
        const result = await addPlayerToTeam(teamId, playerId);
        if (result === true) {
            await fetchTournamentInfo();
        } else {
            setServerMessage(`Ошибка добавления игрока: ${result}`);
            handleShowMyAlert();
        }
    });

    const handleRemovePlayer = (teamId: string, playerId: string) => handleAsyncAction(async () => {
        const result = await removePlayerFromTeam(teamId, playerId);
        if (result === true) {
            await fetchTournamentInfo();
        } else {
            setServerMessage(`Ошибка удаления игрока: ${result}`);
            handleShowMyAlert();
        }
    });

    const handleAddStandin = (teamId: string, playerId: string) => handleAsyncAction(async () => {
        if (tournamentId) {
            const result = await addTeamStandin(tournamentId, teamId, playerId);
            if (result === true) {
                await fetchTournamentInfo();
            } else {
                setServerMessage(`Ошибка добавления замены: ${result}`);
                handleShowMyAlert();
            }
        }
    });

    const handleRemoveStandin = (teamId: string, playerId: string) => handleAsyncAction(async () => {
        if (tournamentId) {
            const result = await removeTeamStandin(tournamentId, teamId, playerId);
            if (result === true) {
                await fetchTournamentInfo();
            } else {
                setServerMessage(`Ошибка удаления замены: ${result}`);
                handleShowMyAlert();
            }
        }
    });

    const handleCancelEditTeam = () => {
        if (!isWaiting) {
            setEditingTeamId(null);
            setSearchTeamQuery('');
        }
    };

    const handleDeleteTeamFromTournament = (teamId: string) => handleAsyncAction(async () => {
        if (tournamentId) {
            const result = await deleteTeamFromTournament(tournamentId, teamId);
            setServerMessage(result === true ? 'Team deleted from tournament' : `Error deleting team: ${result}`);
            handleShowMyAlert();
        }
    });

    const checkAddTeam = (team: ICommonTeamModel) => handleAsyncAction(async () => {
        if (tournamentId) {
            const result = await checkAndAddTeamToTournament(team.teamId, tournamentId);
            if (result === true) {
                await fetchTournamentInfo();
            } else {
                setTeamIdToForce(team.teamId);
                handleShowTeamAlert();
            }
        }
    });

    const handleForceAddTeam = (teamId: string) => handleAsyncAction(async () => {
        if (tournamentId) {
            const result = await forceAddTeamToTournament(teamId, tournamentId);
            if (result === true) {
                await fetchTournamentInfo();
            } else {
                handleCloseTeamAlert();
                setServerMessage(`Error deleting team: ${result}`);
                handleShowMyAlert();
            }
        }
    });

    const handleEndTournament = () => handleAsyncAction(async () => {
        if (tournamentId) {
            const result = await endTournament(tournamentId);
            setServerMessage(result === true ? 'Tournament ended successfully' : `Error ending tournament: ${result}`);
            handleShowMyAlert();
        }
    });

    const handleStartAddingTeams = () => {
        if (!isWaiting) {
            setIsAddingTeams(true);
        }
    };

    const handleCancelAddingTeams = () => {
        if (!isWaiting) {
            setIsAddingTeams(false);
            setFilteredTeams([]);
        }
    };

    const searchPlayers = (query: string) => handleAsyncAction(async () => {
        if (query.length < 3) {
            setFilteredPlayers([]);
            return;
        }
        const filters = { nickNamePart: query, showYourself: true };
        try {
            const results = await getPlayersByFilters(filters);
            setFilteredPlayers(results || []);
        } catch (error) {
            setServerMessage(`Ошибка при поиске игроков: ${error}`);
            handleShowMyAlert();
        }
    });

    const searchTeams = (teamNamePart: string) => handleAsyncAction(async () => {
        if (teamNamePart.length < 3) {
            setFilteredTeams([]);
            return;
        }
        try {
            const results = await getTeamByTeamNamePart(teamNamePart);
            setFilteredTeams(results || []);
        } catch (error) {
            console.error("Ошибка при поиске команд", error);
        }
    });

    const handleServerZoneChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = parseInt(e.target.value) as ServersZonesEnum;
        if (Object.values(ServersZonesEnum).includes(value)) {
            setServerZone(value);
        }
    };

    const handleUpdateServersInfo = () => handleAsyncAction(async () => {
        if (serverZone !== undefined) {
            const result = await getGameServersInfo(serverZone);
            if (Array.isArray(result)) {
                setServersInfo(result);
            } else {
                setServerMessage("Ошибка получения информации о серверах");
                handleShowMyAlert();
            }
        }
    });

    const handleStartSelectedGameServer = (gameServerId: string) => handleAsyncAction(async () => {
        if (tournamentId) {
            const result = await startSelectedGameServer(matchIdToStartServer, gameServerId);
            setServerMessage(result === true ? "Сервер запущен" : "Ошибка запуска игры на сервере");
            handleShowMyAlert();
        }
    });

    const handleCheckServers = () => handleAsyncAction(async () => {
        const result = await startUpdateChecker();
        setServerMessage(result);
        handleShowMyAlert();
    });

    const handleUpdateContainer = (gameServerId: string) => handleAsyncAction(async () => {
        const result = await updateContainer(gameServerId);
        setServerMessage(result);
        handleShowMyAlert();
    });

    const handleStopCheckServers = () => handleAsyncAction(async () => {
        if (tournamentId) {
            const result = await stopUpdateChecker();
            setServerMessage(result);
            handleShowMyAlert();
        }
    });

    const handleGetUpdateCheckerStatus = () => handleAsyncAction(async () => {
        if (tournamentId) {
            const result = await getUpdateCheckerStatus();
            setUpdateCheckerStatus(result);
        }
    });

    const fetchTournamentInfo = async () => {
        if (!tournamentId) return;
        setFetching(true);
        try {
            const data = await getTournamentById(tournamentId);
            if (data) {
                setTournamentInfo(data);
            }
        } finally {
            setFetching(false);
        }
    };

    useEffect(() => {
        fetchTournamentInfo();
    }, [tournamentId]);

    return (
        <div className='tournaments_manage_tab_container'>
            <h4>Ручное администрирование турнира
                <i onClick={() => { window.open(`/tournament/${tournamentInfo?.tournamentId}`, '_blank') }}>{tournamentInfo?.name}</i>
            </h4>
            {fetching ? (
                <div>Загрузка...</div>
            ) : (
                <div className="tournaments_manual_container">
                    <div className="tournaments_manual_info">
                        <div className="tournaments_manual_unit">
                            <h6>Регистрация</h6>
                            <div className="tournaments_manual_buttons">
                                <Button variant="light" onClick={handleStartRegistration} disabled={isWaiting}>Start Registration</Button>
                                <Button variant="dark" onClick={handleStopRegistration} disabled={isWaiting}>Stop Registration</Button>
                            </div>
                        </div>
                        <div className="tournaments_manual_unit_table">
                            <h6>Матчи</h6>
                            {tournamentInfo && tournamentInfo?.tournamentMatches.length !== 0 ? (
                                <MatchesTimetable tournamentInfo={tournamentInfo} forAdmin={true} />
                            ) : (
                                <i>Расписание будет доступно после окончания регистрации</i>
                            )}
                        </div>
                    </div>
                    <div className="tournaments_manual_bracket">
                        <Button variant="warning" onClick={handleRegenerateBracket} disabled={isWaiting}>Regenerate Bracket</Button>
                        {
                            tournamentInfo?.bracket
                                ?
                                <TournamentBracket bracketData={JSON.parse(tournamentInfo?.bracket)} isAdmin={true} editMode={true} playerTeamId={''} />
                                :
                                <CustomSpinner1 sizeSpinnerContainer='300px' size='140px' />
                        }
                    </div>
                    <div className="tournaments_manual_container_layer2">
                        <div className="tournaments_manual_unit">
                            <div className="tournaments_manual_addteams">
                                <h6>Команды</h6>
                                {!isAddingTeams ? (
                                    <Button variant="secondary" onClick={handleStartAddingTeams} disabled={isWaiting}>Добавить</Button>
                                ) : (
                                    <>
                                        <input
                                            type="text"
                                            placeholder="Введите название команды"
                                            value={searchTeamQuery}
                                            onChange={(e) => {
                                                setSearchTeamQuery(e.target.value);
                                                searchTeams(e.target.value);
                                            }}
                                        />
                                        <div className="search-results">
                                            {filteredTeams?.map(team => (
                                                <div key={team.teamId} className="search-result-item">
                                                    {team.name}
                                                    <Button
                                                        variant="primary"
                                                        size="sm"
                                                        onClick={() => checkAddTeam(team)}
                                                        disabled={isWaiting}
                                                    >
                                                        Добавить
                                                    </Button>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="action-buttons">
                                            <Button variant="secondary" onClick={handleCancelAddingTeams} disabled={isWaiting}>Отменить</Button>
                                        </div>
                                    </>
                                )}
                            </div>
                            <table className='current_tournaments_table manual'>
                                <thead>
                                    <tr>
                                        <th className='current_tournaments_table_header name'>Название</th>
                                        <th className='current_tournaments_table_header name'>Игроки</th>
                                        <th className='current_tournaments_table_header name'>Замены</th>
                                        {(tournamentInfo?.statusName === "WaitForApprove" || tournamentInfo?.statusName === "Approved") && (
                                            <th className='current_tournaments_table_header name'>Редактировать команду</th>
                                        )}
                                        <th className='current_tournaments_table_header name'>Удалить</th>
                                    </tr>
                                </thead>
                                <tbody className='current_tournaments_table_body'>
                                    {tournamentInfo?.tournamentTeams.map(team => (
                                        <tr key={team.teamId}>
                                            <td className='current_tournaments_table_td name' onClick={() => { window.open(`/team/${team.teamId}`, '_blank') }}>
                                                {team.name}
                                            </td>

                                            <td className='current_tournaments_table_td'>
                                                {editingTeamId === team.teamId ? (
                                                    <>
                                                        {tournamentInfo?.tournamentTeams.find(t => t.teamId === team.teamId)?.players.map(player => (
                                                            <div key={player.playerId} className='current_tournaments_player'>
                                                                {player.login}
                                                                <Button
                                                                    variant="danger"
                                                                    size="sm"
                                                                    onClick={() => handleRemovePlayer(team.teamId, player.playerId)}
                                                                    disabled={isWaiting}
                                                                >
                                                                    <X />
                                                                </Button>
                                                            </div>
                                                        ))}
                                                        <input
                                                            type="text"
                                                            placeholder="Поиск игроков"
                                                            value={searchQuery}
                                                            onChange={(e) => {
                                                                setSearchQuery(e.target.value);
                                                                searchPlayers(e.target.value);
                                                            }}
                                                        />
                                                        <div className="search-results">
                                                            {filteredPlayers?.map(player => (
                                                                <div key={player.playerId} className="search-result-item">
                                                                    {player.playerName}
                                                                    <Button
                                                                        variant="primary"
                                                                        size="sm"
                                                                        onClick={() => {
                                                                            if (player.playerId)
                                                                                handleAddPlayer(team.teamId, player.playerId)
                                                                        }}
                                                                        disabled={isWaiting}
                                                                    >
                                                                        В состав
                                                                    </Button>
                                                                    <Button
                                                                        variant="dark"
                                                                        size="sm"
                                                                        onClick={() => {
                                                                            if (player.playerId)
                                                                                handleAddStandin(team.teamId, player.playerId)
                                                                        }}
                                                                        disabled={isWaiting}
                                                                    >
                                                                        В замены
                                                                    </Button>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        {team.players?.map(player => (
                                                            <div
                                                                key={player.playerId}
                                                                onClick={() => window.open(`/player/${player.playerId}`, '_blank')}
                                                                className='current_tournaments_player'
                                                            >
                                                                {player.login}
                                                            </div>
                                                        ))}
                                                    </>
                                                )}
                                            </td>
                                            <td className='current_tournaments_table_td'>
                                                {editingTeamId === team.teamId ? (
                                                    <>
                                                        {tournamentInfo?.tournamentTeams.find(t => t.teamId === team.teamId)?.standins.map(player => (
                                                            <div key={player.playerId} className='current_tournaments_player'>
                                                                {player.login}
                                                                <Button
                                                                    variant="danger"
                                                                    size="sm"
                                                                    onClick={() => handleRemoveStandin(team.teamId, player.playerId)}
                                                                    disabled={isWaiting}
                                                                >
                                                                    <X />
                                                                </Button>
                                                            </div>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <>
                                                        {tournamentInfo?.tournamentTeams.find(t => t.teamId === team.teamId)?.standins.map(player => (
                                                            <div
                                                                key={player.playerId}
                                                                onClick={() => window.open(`/player/${player.playerId}`, '_blank')}
                                                                className='current_tournaments_player'
                                                            >
                                                                {player.login}
                                                            </div>
                                                        ))}
                                                    </>
                                                )}
                                            </td>
                                            <td className='current_tournaments_table_td'>
                                                {editingTeamId === team.teamId ? (
                                                    <>
                                                        <Button variant="secondary" onClick={handleCancelEditTeam} disabled={isWaiting}>Закрыть</Button>
                                                    </>
                                                ) : (
                                                    <Button variant="secondary" onClick={() => handleEditRoaster(team)}>Редактировать состав</Button>
                                                )}
                                            </td>
                                            <td className='current_tournaments_table_td'>
                                                <Button variant="danger" onClick={() => handleDeleteTeamFromTournament(team.teamId)} disabled={isWaiting}>Удалить с Турнира</Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <h6>Управление серверами</h6>
                            <div className="admin_servers_manage">
                                <div className="server_zone_container">
                                    <div className="server_zone_search">
                                        <FloatingLabel label="Server Zone" className='create_tourn_label'>
                                            <Form.Select
                                                className="serverzone_option"
                                                onChange={handleServerZoneChange}
                                                value={serverZone}
                                            >
                                                <option defaultValue=''>-</option>
                                                <option value={ServersZonesEnum.Moscow}>Moscow</option>
                                                <option value={ServersZonesEnum.Novosibirsk}>Novosibirsk</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                        <Button variant="secondary" onClick={handleUpdateServersInfo} disabled={isWaiting}><Search /></Button>
                                    </div>
                                    <div className="server_zones_units">
                                        {serversInfo && serversInfo.length > 0 ? (
                                            serversInfo.map((server: ISourceGameServerInfo) => (
                                                <div key={server.id} className="servers_info_container">
                                                    <p>Server Name: {server.Name || server.dedicatedServerName}</p>
                                                    <p>Reserved: {server.isReserved ? "Yes" : "No"}</p>
                                                    <p>App ID: {server.appId}</p>
                                                    <p>State: {server.gameServerState}</p>
                                                    <p>Update State: {server.updateState}</p>
                                                    <Button onClick={() => handleStartSelectedGameServer(server.id)} disabled={isWaiting}>Запустить для</Button>
                                                    <Form.Select
                                                        className="create_tournament_form_control option"
                                                        onChange={(e) => setMatchIdToStartServer(e.target.value)}
                                                        value={matchIdToStartServer}
                                                    >
                                                        {tournamentInfo?.tournamentMatches.map((match) => (
                                                            <option key={match.matchId} value={match.matchId}>
                                                                {match.teamNameA} - {match.teamNameB}
                                                            </option>
                                                        ))}
                                                    </Form.Select>

                                                    <Button variant="success" onClick={() => handleUpdateContainer(server.id)} disabled={isWaiting}>Обновить</Button>
                                                </div>
                                            ))
                                        ) : (
                                            <p>No servers available for the selected zone.</p>
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <Button variant='dark' onClick={handleCheckServers} disabled={isWaiting}>Запуск автообновления</Button>
                                    <Button variant='dark' onClick={handleStopCheckServers} disabled={isWaiting}>Остановка автообновления</Button>
                                    <div>
                                        <Button variant='secondary' onClick={handleGetUpdateCheckerStatus} disabled={isWaiting}>Получить статус автообновления</Button>
                                        {updateCheckerStatus && (
                                            <p>Полученный статус: {updateCheckerStatus}</p>
                                        )}
                                    </div>
                                    <Button variant='success' onClick={handleStopCheckServers} disabled={isWaiting}>Обновить все контейнеры</Button>
                                </div>
                            </div>
                            <Button variant="secondary" onClick={() => setFetchResults(true)} disabled={isWaiting}>Получить результаты</Button>
                            {fetchResults && tournamentId && (
                                <ResultsTable tournamentId={tournamentId} />
                            )}
                            <Button variant="danger" onClick={handleEndTournament} disabled={isWaiting}>Закончить Турнир</Button>
                        </div>
                    </div>
                </div>
            )}
            <MyAlert
                onHide={handleCloseMyAlert}
                show={showMyAlert}
                alertText={serverMessage}
                buttons={[
                    { text: 'Назад', onClick: handleCloseMyAlert }
                ]}
            />
            <MyAlert
                onHide={handleCloseTeamAlert}
                show={showTeamAlert}
                alertText='Команда не подходит на данный момент'
                buttons={[
                    { text: 'Force Добавить', onClick: (() => handleForceAddTeam(teamIdToForce)) },
                    { text: 'Отменить', onClick: handleCloseTeamAlert }
                ]}
            />
        </div>
    );
};

export default ManualTournament;
import { IUpdateTournamentBracket } from "../interfaces/IUpdateTournamentBracket";
import { authInstance } from "../../../http";
import { AxiosResponse } from "axios";
import { IBracketData } from "../interfaces/IBracketData";

export const updateTournamentBracket = async (
    changesData: IUpdateTournamentBracket
): Promise<IBracketData | null> => {
    try {
        const response: AxiosResponse<IBracketData> = await authInstance.post(
            `TournamentsCommon/UpdateTournamentByBracket`,
            changesData
        );

        return response.data;
    } catch (e: any) {
        alert(e.response?.data?.message || "Ошибка обновления турнирной сетки");
        return null;
    }
};

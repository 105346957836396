import './SingleBracket.css';

import React, { useEffect, useState } from "react";
import {
    useBracketData,
    useChangedMatches,
    useEditMode,
    useEditTournamentBracket,
    useOpponentHover, useOriginalBracketData,
    usePlayerTeam
} from "../../providers/TournamentBracketContext";

import { IBracketData } from "../../interfaces/IBracketData";

import BracketMatch from "../BracketMatch/BracketMatch";
import RenderLines from "../../common/RenderLines";
import {IRound} from "../../interfaces/IRound";
import {IMatch} from "../../interfaces/IMatch";

interface SingleBracketProps {
    bracketData: IBracketData;
    playerTeamId: string | null;
    editMode: boolean;
}

const SingleBracket: React.FC<SingleBracketProps> = ({ bracketData, playerTeamId, editMode }): React.JSX.Element => {
    const [currentBracketData , setCurrentBracketData ] = useState<IBracketData>(bracketData);
    const [isMount, setIsMount] = useState<boolean>(false);
    const [roundHeight, setRoundHeight] = useState<number>(0);
    const { hoveredOpponentId } = useOpponentHover();
    const { setPlayerTeamId } = usePlayerTeam();
    const { isEditMode, setEditMode } = useEditMode();
    const { undoChanges, saveChanges } = useEditTournamentBracket();
    const { contextBracketData, setContextBracketData } = useBracketData();
    const { originalBracketData, setOriginalBracketData } = useOriginalBracketData();

    const { changedMatches } = useChangedMatches()
    useEffect(() => {
        console.log(changedMatches)
    }, [changedMatches])

    useEffect(() => {
        setPlayerTeamId(playerTeamId);
        setContextBracketData(bracketData);
        setOriginalBracketData(JSON.parse(JSON.stringify(bracketData)));
        setTimeout(() => {
            setIsMount(true);
        }, 50)
    }, [bracketData]);

    useEffect(() => {
        if (contextBracketData) {
            setCurrentBracketData(contextBracketData);
        }
    }, [contextBracketData]);

    useEffect(() => {
        if (currentBracketData.round[0]) {
            let countMatches: number = currentBracketData.match.filter(m => m.round_id === currentBracketData.round[0].id).length;
            setRoundHeight((countMatches * 60) + (countMatches * 50) + 100);
        }
    }, [currentBracketData.round, currentBracketData.match]);

    const renderMatchesByRoundId = (roundId: number): React.JSX.Element => {
        let currentRoundMatches: IMatch[] = currentBracketData.match.filter(m => m.round_id === roundId);

        return (
            <>
                {currentRoundMatches.map((m: IMatch): React.JSX.Element => {
                    let bracketType: string | undefined = currentBracketData.stage.find(s => s.id === m.stage_id)?.type;

                    const isMatchHighlighted: boolean | string | null = hoveredOpponentId && (m.opponent1?.id === hoveredOpponentId || m.opponent2?.id === hoveredOpponentId);

                    return (
                        <div
                            className={`SingleBracket_matchContainer ${isMatchHighlighted ? 'highlighted' : ''}`}
                            id={`match-${m.id}`}
                            key={m.id}
                        >
                            <BracketMatch
                                match={m}
                                bracketType={bracketType}
                            />
                        </div>
                    );
                })}
            </>
        );
    };

    const renderRounds = (): React.JSX.Element => {
        return (
            <>
                {bracketData.round.map((r: IRound, idx: number): React.JSX.Element => (
                    <div
                        className="SingleBracket_round"
                        style={{ height: roundHeight }}
                        key={idx}
                    >
                        {renderMatchesByRoundId(r.id)}
                    </div>
                ))}
            </>
        );
    };

    const renderGroups = (): React.JSX.Element => {
        return (
            <div className="SingleBracket_group">
                {renderRounds()}
                {isMount && <RenderLines bracketMatches={currentBracketData.match} bracketGroups={currentBracketData.group} bracketRounds={currentBracketData.round} />}
            </div>
        );
    };

    const renderEditButtons = (): React.JSX.Element => {
        return (
            <>
                {editMode && (
                    <div className='DoubleBracket_editButtons'>
                        {isEditMode ? (
                            <>
                                <button onClick={() => {
                                    undoChanges();
                                    setEditMode(false);
                                    if (originalBracketData) {
                                        setCurrentBracketData(originalBracketData);
                                    }
                                }}>ОТМЕНА</button>
                                <button onClick={saveChanges}>СОХРАНИТЬ</button>
                            </>
                        ) : (
                            <button onClick={() => setEditMode(true)}>РЕДАКТИРОВАНИЕ</button>
                        )}
                    </div>
                )}
            </>
        )
    }

    const renderSingleBracket = (): React.JSX.Element => {
        return (
            <div className='SingleBracket_group_wrapper'>
                {renderEditButtons()}
                {renderGroups()}
            </div>
        )
    }

    return (
        <div className="SingleBracket_container">
            {renderSingleBracket()}
        </div>
    );
};

export default SingleBracket;
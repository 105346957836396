import React, { useEffect, useState } from 'react';
import { Form, Pagination } from 'react-bootstrap';
import { TrashFill } from 'react-bootstrap-icons';
import { getAllPlayers } from '../../../../http/adminActionsAPI';
import CustomSpinner1 from '../../../../components/CustomSpinners/CustomSpinner1';
import { IPlayerPageModel } from '../../../../models/dto/player/IPlayerPageModel';

const PlayerManage: React.FC = () => {
    const [sortType, setSortType] = useState('nearest');
    const [fetchingPlayerInfo, setFetchingPlayerInfo] = useState(true);
    const [playerList, setPlayerList] = useState<IPlayerPageModel[]>([])
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const playersPerPage = 20;
    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    const indexOfLastPlayer = currentPage * playersPerPage;
    const indexOfFirstPlayer = indexOfLastPlayer - playersPerPage;

    useEffect(() => {
        setFetchingPlayerInfo(true);
        getAllPlayers()
            .then(data => {
                if (data) {
                    setPlayerList(data);
                }
            })
            .finally(() => setFetchingPlayerInfo(false));
    }, [])

    const handleSortTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSortType(event.target.value);
    };

    const sortPlayers = (players: IPlayerPageModel[]) => {
        switch (sortType) {
            case 'nearest':
                return players.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            case 'no-steam':
                return players.filter(player => !player.steamID);
            default:
                return players;
        }
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1);
    };

    const filterPlayers = (players: IPlayerPageModel[]) => {
        return players.filter(player =>
            searchQuery.length < 2 ||
            player.login.toLowerCase().includes(searchQuery.toLowerCase())
        );
    };
    const playersToDisplay = sortPlayers(filterPlayers(playerList));

    const currentPlayers = playersToDisplay.slice(indexOfFirstPlayer, indexOfLastPlayer);

    const renderPaginationItems = () => {
        const totalPages = Math.ceil(playersToDisplay.length / playersPerPage);
        const paginationItems = [];
        const maxPagesToShow = 7;

        if (totalPages <= maxPagesToShow) {
            for (let page = 1; page <= totalPages; page++) {
                paginationItems.push(
                    <Pagination.Item
                        key={page}
                        active={page === currentPage}
                        onClick={() => paginate(page)}
                    >
                        {page}
                    </Pagination.Item>
                );
            }
        } else {
            paginationItems.push(
                <Pagination.Item key={1} active={currentPage === 1} onClick={() => paginate(1)}>
                    1
                </Pagination.Item>
            );

            if (currentPage > 4) {
                paginationItems.push(<Pagination.Ellipsis key="start-ellipsis" />);
            }

            const startPage = Math.max(2, currentPage - 1);
            const endPage = Math.min(totalPages - 1, currentPage + 1);

            for (let page = startPage; page <= endPage; page++) {
                paginationItems.push(
                    <Pagination.Item
                        key={page}
                        active={page === currentPage}
                        onClick={() => paginate(page)}
                    >
                        {page}
                    </Pagination.Item>
                );
            }

            if (currentPage < totalPages - 3) {
                paginationItems.push(<Pagination.Ellipsis key="end-ellipsis" />);
            }

            paginationItems.push(
                <Pagination.Item key={totalPages} active={currentPage === totalPages} onClick={() => paginate(totalPages)}>
                    {totalPages}
                </Pagination.Item>
            );
        }

        return paginationItems;
    };

    return (
        <div className='tournaments_manage_tab_container'>
            <h4>Управление игроками</h4>
            <div className='current_tournaments_unit'>
                <div className='current_tournaments_unit_table'>
                    <div className='all_tourn_header'>Все игроки</div>
                    <div className='tournaments_manage_filter_container'>
                        <Form.Control
                            type="text"
                            placeholder="Поиск по никнейму"
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                        <Form.Select
                            className="tournaments_manage_filter"
                            name="sortType"
                            value={sortType}
                            required
                            onChange={handleSortTypeChange}
                        >
                            <option value="nearest">Недавно созданные</option>
                            <option value="no-steam">Нет SteamId</option>
                        </Form.Select>
                    </div>
                    {fetchingPlayerInfo ? (
                        <CustomSpinner1 />
                    ) : (
                        <table className='current_tournaments_table'>
                            <thead>
                                <tr>
                                    <th className='current_tournaments_table_header'>Аватар</th>
                                    <th className='current_tournaments_table_header'>Никнейм</th>
                                    <th className='current_tournaments_table_header'>Команды</th>
                                    <th className='current_tournaments_table_header'>Дата регистрации</th>
                                    <th className='current_tournaments_table_header'>ФИО</th>
                                    <th className='current_tournaments_table_header'>Steam ID</th>
                                    <th className='current_tournaments_table_header'>Удалить</th>
                                </tr>
                            </thead>
                            <tbody className='current_tournaments_table_body'>
                                {currentPlayers.map(player => (
                                    <tr key={player.playerId}>
                                        <td className='current_tournaments_table_td'>
                                            <img src={`${process.env.REACT_APP_IMG_STORAGE_URL}${player.avatarURL}`} className='user_stat_table_td_img' alt='' />
                                        </td>
                                        <td className='current_tournaments_table_td name' onClick={() => { window.open(`/player/${player.playerId}`, '_blank') }}>
                                            {player.login}
                                        </td>
                                        <td className='current_tournaments_table_td name'>
                                            {
                                                player.playerTeams?.map(team => (
                                                    <span key={team.teamId} onClick={() => { window.open(`/team/${team.teamId}`, '_blank') }}>
                                                        {team.gameTypeName} - {team.name}
                                                    </span>
                                                ))
                                            }
                                        </td>
                                        <td className='current_tournaments_table_td'>
                                            {new Date(player.createdAt).toLocaleDateString()}
                                        </td>
                                        <td className='current_tournaments_table_td'>
                                            {player.name} {player.surname}
                                        </td>
                                        <td className={`current_tournaments_table_td ${player.steamID ? 'approved' : 'not-approved'}`}>
                                            {player.steamID !== '' ? player.steamID : 'Не привязан'}
                                        </td>
                                        <td className='current_tournaments_table_td'>
                                            <TrashFill className='general_icon' />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
                <div className='current_tournaments_pagination'>
                    <Pagination>
                        {renderPaginationItems()}
                    </Pagination>
                </div>
            </div >
        </div >
    );
};

export default PlayerManage;
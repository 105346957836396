import React, { useEffect, useRef, useState, useCallback, useLayoutEffect } from 'react';
import './SteppedLineTo.css';

interface SteppedLineToProps {
    from: string;
    to: string;
    borderColor?: string;
    borderWidth?: number;
    zIndex?: number;
    animate?: boolean;
    isStraightLine?: boolean;
    isFlash?: boolean
}

const SteppedLineTo: React.FC<SteppedLineToProps> = ({
    from,
    to,
    borderColor = '#7a7a80',
    borderWidth = 2,
    zIndex = 5,
    animate = false,
    isStraightLine = false,
    isFlash = false
}: SteppedLineToProps): React.JSX.Element => {
    const [lineCoords, setLineCoords] = useState({ x1: 0, y1: 0, x2: 0, y2: 0, midX: 0, midY1: 0, midY2: 0 });
    const resizeObserver = useRef<ResizeObserver | null>(null);
    
    const updateLinePosition = useCallback(() => {
        const fromElement = document.getElementById(from);
        const toElement = document.getElementById(to);

        if (!fromElement || !toElement) return;
        
        const x1: number = fromElement.offsetLeft + fromElement.offsetWidth;
        const y1: number = fromElement.offsetTop + fromElement.offsetHeight / 2;
        const x2: number = toElement.offsetLeft;
        const y2: number = toElement.offsetTop + toElement.offsetHeight / 2;
        
        const midX = (x1 + x2) / 2;
        
        setLineCoords({ x1, y1, x2, y2, midX, midY1: y1, midY2: y2 });
    }, [from, to]);
    
    useEffect(() => {
        const fromElement = document.getElementById(from);
        const toElement = document.getElementById(to);
        
        if (fromElement && toElement) {
            resizeObserver.current = new ResizeObserver(updateLinePosition);
            resizeObserver.current.observe(fromElement);
            resizeObserver.current.observe(toElement);
        }
        
        updateLinePosition();
        
        window.addEventListener('resize', updateLinePosition);
        return () => {
            window.removeEventListener('resize', updateLinePosition);
            resizeObserver.current?.disconnect();
        };
    }, [from, to, updateLinePosition]);

    useLayoutEffect(() => {
        updateLinePosition();
        window.addEventListener('resize', updateLinePosition);
        return () => {
            window.removeEventListener('resize', updateLinePosition);
        };
    }, [from, to, updateLinePosition]);
    
    return (
        <svg
            className={`${animate ? 'SteppedLineTo_animateLine' : isFlash ? 'SteppedLineTo_flashLine' : 'SteppedLineTo_baseline'}`}
            style={{ zIndex }}
        >
            <path
                className={`SteppedLineTo_linePath ${animate ? 'animate' : ''}`}
                d={
                    isStraightLine
                        ? `M${lineCoords.x1},${lineCoords.y1} L${lineCoords.x2},${lineCoords.y2}`
                        : `M${lineCoords.x1},${lineCoords.y1} L${lineCoords.midX},${lineCoords.y1} L${lineCoords.midX},${lineCoords.y2} L${lineCoords.x2},${lineCoords.y2}` // Ступенчатая линия
                }
                stroke={borderColor}
                strokeWidth={borderWidth}
                fill="none"
            />
        </svg>
    );
};

export default SteppedLineTo;
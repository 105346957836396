import { useState, useEffect } from 'react';
import { ArrowLeft } from "react-bootstrap-icons";
import './BracketMatchUpdatedMenu.css';
import { useEditTournamentBracket } from "../../providers/TournamentBracketContext";
import { IOpponent } from "../../interfaces/IOpponent";
import { CommonConstants } from "../../helpers/Constants";

interface IOpponentNameAndTag {
    tag: string | null | undefined;
    name: string | null | undefined;
}

interface BracketMatchUpdatedMenuProps {
    isEditMode: boolean;
    showMenu: boolean;
    opponent1NameAndTag: IOpponentNameAndTag;
    opponent2NameAndTag: IOpponentNameAndTag;
    menuRef: React.RefObject<HTMLDivElement>;
    matchId: string;
    matchDate: string | null;
    opponent1: IOpponent | null;
    opponent2: IOpponent | null;
}

const BracketMatchUpdatedMenu: React.FC<BracketMatchUpdatedMenuProps> = ({
    isEditMode,
    showMenu,
    opponent1NameAndTag,
    opponent2NameAndTag,
    menuRef,
    matchId,
    matchDate,
    opponent1,
    opponent2
}: BracketMatchUpdatedMenuProps) => {
    const { updateMatchScore, updateMatchResult, updateMatchDateTime } = useEditTournamentBracket();
    const [opponent1Score, setOpponent1Score] = useState<number>(0);
    const [opponent2Score, setOpponent2Score] = useState<number>(0);
    const [isUpdatedMatchDate, setUpdatedMatchDate] = useState<boolean>(false);
    const [isUpdatedMatchScore, setUpdatedMatchScore] = useState<boolean>(false);
    const [newDateTime, setNewDateTime] = useState<string>("");

    useEffect(() => {
        if (isUpdatedMatchScore) {
            setOpponent1Score(Number(opponent1NameAndTag.name) || 0);
            setOpponent2Score(Number(opponent2NameAndTag.name) || 0);
        }
    }, [isUpdatedMatchScore, opponent1NameAndTag.name, opponent2NameAndTag.name]);

    const toLocalDatetimeString = (utcDate: string): string => {
        const localDate = new Date(utcDate);
        const offsetMs = localDate.getTimezoneOffset() * 60000;
        const localTime = new Date(localDate.getTime() - offsetMs);
        return localTime.toISOString().slice(0, 16);
    };

    useEffect(() => {
        if (matchDate) {
            setNewDateTime(toLocalDatetimeString(matchDate));
        }
    }, [matchDate]);

    const onUpdateMatchDate = () => {
        setUpdatedMatchDate(true)
    }

    const onUpdateMatchScore = () => {
        setUpdatedMatchScore(true)
    }

    const onBackMenu = () => {
        setUpdatedMatchScore(false);
        setUpdatedMatchDate(false);
    }

    const renderUpdatedMatchScore = (): React.JSX.Element => {
        const handleOpponent1ScoreChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            setOpponent1Score(Number(e.target.value));
        };

        const handleOpponent2ScoreChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            setOpponent2Score(Number(e.target.value));
        };

        const handleMatchScoreChange = () => {
            if (
                opponent1Score !== null &&
                opponent2Score !== null &&
                opponent1Score !== undefined &&
                opponent2Score !== undefined
            ) {
                updateMatchScore(matchId, opponent1Score, opponent2Score);
            }

            onBackMenu();
        };

        const handleMatchResultChange = (opponentId: string | null | undefined) => {
            if (opponentId) {
                updateMatchResult(matchId, opponentId)
            }

            onBackMenu();
        };

        return (
            <>
                <div className='BracketMatch_updatedMenu_backArrowContainer' onClick={onBackMenu}>
                    <ArrowLeft className='BracketMatch_updatedMenu_backArrow'/>
                    <button onClick={handleMatchScoreChange}>ОК</button>
                </div>

                <div className='BracketMatch_updatedMenu_score'>
                    <span>{opponent1NameAndTag.tag}</span>

                    <div>
                        <span onClick={() => handleMatchResultChange(opponent1?.id)}>win</span>
                        <input
                            value={opponent1Score}
                            onChange={handleOpponent1ScoreChange}
                        />
                    </div>
                </div>

                <div className='BracketMatch_updatedMenu_score'>
                    <span>{opponent2NameAndTag.tag}</span>

                    <div>
                        <span onClick={() => handleMatchResultChange(opponent2?.id)}>win</span>
                        <input
                            value={opponent2Score}
                            onChange={handleOpponent2ScoreChange}
                        />
                    </div>
                </div>
            </>
        )
    }

    const renderUpdatedMatchDate = (): React.JSX.Element => {
        const handleDateTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            setNewDateTime(e.target.value);
        };

        const handleDateTimeUpdate = () => {
            if (newDateTime && matchId) {
                const utcDate = new Date(newDateTime).toISOString();
                updateMatchDateTime(matchId, utcDate);
                setUpdatedMatchDate(false);
            }
        };

        return (
            <>
                <div className='BracketMatch_updatedMenu_backArrowContainer date' onClick={onBackMenu}>
                    <ArrowLeft className='BracketMatch_updatedMenu_backArrow'/>
                </div>

                <div className='BracketMatch_updatedMenu_date'>
                    <input
                        type='datetime-local'
                        value={newDateTime}
                        onChange={handleDateTimeChange}
                    />
                    <button onClick={handleDateTimeUpdate}>OK</button>
                </div>
            </>
        );
    };

    const renderMenuButtons = (): React.JSX.Element => {
        return (
            <>
                {(
                    (opponent1?.id && opponent1.id !== CommonConstants.GuidEmpty) &&
                    (opponent2?.id && opponent2.id !== CommonConstants.GuidEmpty)) && (
                    <button onClick={onUpdateMatchScore}>Редактировать счёт</button>
                )}

                <button onClick={onUpdateMatchDate}>Редактировать дату</button>

                {(
                    (opponent1?.score || opponent1?.result) &&
                    (opponent2?.score || opponent2?.result)) && (
                    <button onClick={() => window.open(`/match/${matchId}`, '_blank')}>Открыть</button>
                )}
            </>
        )
    }

    const renderMenu = (): React.JSX.Element => {
        return (
            <>
                {isEditMode && showMenu && (
                    <div className="BracketMatch_updatedMenu" ref={menuRef}>
                        {isUpdatedMatchScore ? (
                            renderUpdatedMatchScore()
                        ) : isUpdatedMatchDate ? (
                            renderUpdatedMatchDate()
                        ) : (
                            renderMenuButtons()
                        )}
                    </div>
                )}
            </>
        )
    }

    return (
        renderMenu()
    );
};

export default BracketMatchUpdatedMenu;